import { client } from "@/lib/utils";
import { queryOptions, useMutation, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { Suspense, useEffect, useState } from "react";
import { DialogContent } from "./ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs";
import { Switch } from "./ui/switch";
import { DialogTitle } from "@radix-ui/react-dialog";
import { useAuth } from "@/lib/auth";

export const getProducts = async () => {
  try {
    const products = await client.api.billing.products.$get()
    return products.json()
  } catch (error) {
    console.error('Failed to fetch repo files:', error);
    throw error;
  }
}

export const productsQueryOptions = () => {
  return queryOptions({
    queryKey: ['products'],
    queryFn: () => getProducts()
  })
}

const BillingTab = () => {
 const auth = useAuth()
  const [billingCycle, setBillingCycle] = useState<'monthly' | 'yearly'>('monthly');
  const toggleBillingCycle = () => {
    setBillingCycle(prev => prev === 'monthly' ? 'yearly' : 'monthly');
  };

  const subscribe = useMutation({
    mutationFn: async (priceId: string) => {
      const response = await client.api.billing["create-checkout-session"].$post({
        json: {
          priceId: priceId
        }
      })
      return response.json()
    }
  })

  const createPortal = useMutation({
    mutationFn: async () => {
      const response = await client.api.billing["create-portal-session"].$post()
      return response.json()
    }
  })

  const formatPrice = (price: number) => {
    return `${price / 100}`;
  };

  const products = useSuspenseQuery({
    queryKey: ['products'],
    queryFn: () => getProducts()
  })

  return (
    <TabsContent value="billing">
      {auth.billingPlan?.status === "active" || (auth.billingPlan?.status === "canceled" && !auth.billingPlan?.expired) ? <div className="flex flex-col gap-4">
          <p>You are on the <span className="font-bold">{auth.billingPlan.planName!.charAt(0).toUpperCase() + auth.billingPlan.planName?.slice(1)}</span> plan</p>
          <p>Expires at {auth.billingPlan.expiresAt?.toLocaleDateString()}</p>
          <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600" onClick={async () => {
            const url = await createPortal.mutateAsync()
            if ('url' in url && url.url) {
              window.open(url.url, '_blank');
            }
          }}>Manage Subscription</button>
        </div> : <div className="grid grid-cols-2 gap-8">
     
      {products.data.map((product) => (
        <div key={product.id} className="border rounded p-4 flex flex-col gap-8">
          <div className="flex flex-col gap-2">
            <h3 className="text-md">{product.name}</h3>
            <p className="text-lg font-black text-gray-800">
              {billingCycle === 'monthly'
                ? `$${formatPrice(product.variants.find(v => v.interval === 'month')?.unitAmount ?? 0)}/month`
                : `$${formatPrice(product.variants.find(v => v.interval === 'year')?.unitAmount ?? 0)}/year`}
            </p>
            <div className="flex items-center gap-2">
              <Switch
                checked={billingCycle === 'yearly'}
                onCheckedChange={toggleBillingCycle}
              />
              <span className="text-sm">{billingCycle === 'monthly' ? 'Monthly' : 'Yearly'}</span>
            </div>
          </div>
          <div className="flex flex-col">

            <button 
              onClick={async () => {
                const checkoutUrl = await subscribe.mutateAsync(product.variants.find(v => v.interval === (billingCycle === 'monthly' ? 'month' : 'year'))?.priceId ?? '')
                if ('url' in checkoutUrl && checkoutUrl.url) {
                  window.open(checkoutUrl.url, '_blank');
                }
              }}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
              Subscribe
            </button>
          </div>
        </div>
      ))}
    </div>}

    </TabsContent>
  )
}

export function SettingsDialog({isSettingsOpen}: {isSettingsOpen: boolean}) {
  const navigate = useNavigate()
  const queryClient = useQueryClient();
  const logout = useMutation({
    mutationFn: async () => {
      await client.api.auth.logout.$post()
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['current_user'] })
      queryClient.setQueryData(['current_user'], null)
      localStorage.removeItem('REACT_QUERY_OFFLINE_CACHE')
      navigate({ to: '/login' })
    }
  })


  return (
    <DialogContent className="max-w-5xl">
      <DialogTitle></DialogTitle>
      <div>
        <Tabs defaultValue={isSettingsOpen ? 'billing' : 'account'} className="w-full">
          <div className="flex flex-row gap-16">
            <TabsList className="flex flex-col min-h-[400px] justify-start items-start w-[170px]">
              <TabsTrigger value="account" className="p-0 py-1 px-2 w-full justify-start">Account</TabsTrigger>
              <TabsTrigger value="billing" className="p-0 py-1 px-2 w-full justify-start">Billing</TabsTrigger>
            </TabsList>

            <div className="flex-1 ">
              <TabsContent value="account">
                <div className="flex flex-col gap-2 py-2">
                  <button className="border rounded p-2 bg-white text-gray-600 hover:text-gray-800 hover:bg-gray-100" onClick={async () => {
                    await logout.mutateAsync()
                  }}>Logout</button>
                </div>
              </TabsContent>
              <Suspense>
                <BillingTab />
              </Suspense>

             
            </div>
          </div>
        </Tabs>
      </div>

    </DialogContent>
  )
}