import React, { createContext, useCallback, useContext, useMemo, useState, useEffect } from "react";
import { schema } from "@readmewriter/db"
import { z } from "zod"
import { useParams } from "@tanstack/react-router";
import { ChevronRight, ChevronDown, Folder, FileCode2 } from 'lucide-react'

interface FileTreeContextType {
  openFolders: Set<string>;
  toggleFolder: (folderId: string) => void;
  openParentFolders: (fileId: string) => void;
}

const FileTreeContext = createContext<FileTreeContextType | undefined>(undefined);

export const FileTreeProvider: React.FC<{ children: React.ReactNode, files?: (z.infer<typeof schema.selectFileSchema> & { annotations: z.infer<typeof schema.selectAnnotationSchema>[] })[] }> = ({ children, files }) => {
  const [openFolders, setOpenFolders] = useState<Set<string>>(new Set());
  const { fileId } = useParams({ strict: false });

  const toggleFolder = (folderId: string) => {
    setOpenFolders((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(folderId)) {
        newSet.delete(folderId);
      } else {
        newSet.add(folderId);
      }
      return newSet;
    });
  };

  const openParentFolders = useCallback((fileId: string) => {
    setOpenFolders(prevOpenFolders => {
      const newOpenFolders = new Set(prevOpenFolders);
      let currentFile = files?.find(f => f.id === fileId);
      while (currentFile?.parentId) {
        newOpenFolders.add(currentFile.parentId);
        currentFile = files?.find(f => f.id === currentFile?.parentId);
      }
      return newOpenFolders;
    });
  }, [files]);

  useEffect(() => {
    if (fileId) {
      openParentFolders(fileId);
    }
  }, [fileId, openParentFolders]);

  return (
    <FileTreeContext.Provider value={{ openFolders, toggleFolder, openParentFolders }}>
      {children}
    </FileTreeContext.Provider>
  );
};

export const useFileTree = () => {
  const context = useContext(FileTreeContext);
  if (context === undefined) {
    throw new Error('useFileTree must be used within a FileTreeProvider');
  }
  return context;
};


type FileWithChildren = z.infer<typeof schema.selectFileSchema> & {
  children: FileWithChildren[];
  annotations?: z.infer<typeof schema.selectAnnotationSchema>[];
};
interface FileTreeProps {
  files?: (z.infer<typeof schema.selectFileSchema> & { annotations: z.infer<typeof schema.selectAnnotationSchema>[] })[];
  onFileClick: (file: z.infer<typeof schema.selectFileSchema>) => void;
}

const extractFileName = (path: string | null): string => {
  if (!path) return "";
  const parts = path.split('/');
  return parts[parts.length - 1];
};

const buildFileTree = (files?: (z.infer<typeof schema.selectFileSchema> & { annotations: z.infer<typeof schema.selectAnnotationSchema>[] })[]) => {
  const fileMap = new Map<string, FileWithChildren>();

  // Initialize the map with all files
  files?.forEach(file => {
    fileMap.set(file.id, { ...file, name: extractFileName(file.name), children: [] });
  });

  let root: FileWithChildren[] = [];

  // Build the tree structure
  files?.forEach(file => {
    if (file.parentId) {
      const parent = fileMap.get(file.parentId);
      if (parent) {
        parent.children.push(fileMap.get(file.id)!);
      }
    } else {
      root.push(fileMap.get(file.id)!);
    }
  });

  return root;
}
const getColorCounts = (annotations: z.infer<typeof schema.selectAnnotationSchema>[]) => {
  const colorCounts = annotations.reduce((acc, annotation) => {
    if (annotation.color) {
      acc[annotation.color] = (acc[annotation.color] || 0) + 1;
    }
    return acc;
  }, {} as Record<string, number>);

  const totalExtra = Object.values(colorCounts).reduce((acc, count) => acc + (count - 1), 0);

  return { colorCounts, totalExtra };
};


const FileTree: React.FC<FileTreeProps> = ({ files, onFileClick }) => {
  const fileTree = useMemo(() => buildFileTree(files), [files]);
  const { openFolders, toggleFolder } = useFileTree();
  return (
    <ul className="flex flex-col">
      {fileTree.map((file) => (
        <FileTreeItem
        key={file.id}
        file={file}
        level={0}
        isLast={false}
        onClick={onFileClick}
        isOpen={openFolders.has(file.id)}
        toggleFolder={toggleFolder}
      />
      ))}
    </ul>
  );
};

const FileTreeItem: React.FC<{
  file: FileWithChildren
  level: number;
  isLast: boolean;
  onClick: (file: z.infer<typeof schema.selectFileSchema>) => void;
  isOpen: boolean;
  toggleFolder: (folderId: string) => void;
}> = ({ file, level, isLast, onClick, isOpen, toggleFolder }) => {
  const hasChildren = file.children && file.children.length > 0
  const { openFolders  } = useFileTree();
  const { fileId } = useParams({ strict: false });
  const isActive = fileId === file.id;


  const handleClick = useCallback(() => {
    if (file.fileType !== "tree") {
      onClick(file);
    } else {
      toggleFolder(file.id);
    }
  }, [file, onClick, toggleFolder]);


  const { colorCounts, totalExtra } = useMemo(() => getColorCounts(file.annotations || []), [file.annotations]);

  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '1px 0',
          cursor: 'pointer',
          fontSize: '12px',
        }}
        onClick={handleClick}
      >
        {level > 0 && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              bottom: isLast ? '50%' : 0,
              left: `${(level - 1) * 12 + 5}px`,
              borderLeft: '1px solid #ccc',
            }}
          />
        )}
        {level > 0 && (
          <div
            style={{
              position: 'absolute',
              width: '8px',
              top: '50%',
              left: `${(level - 1) * 12 + 5}px`,
              borderBottom: '1px solid #ccc',
            }}
          />
        )}
        <div style={{ width: `${level * 12}px` }} />
        {hasChildren ? (
          isOpen ? <ChevronDown size={12} /> : <ChevronRight size={12} />
        ) : (
          <span style={{ width: '12px' }} />
        )}
        {file.fileType === "tree" ? <Folder size={12} /> : <FileCode2 size={12} />}
        <span
          style={{
            marginLeft: '3px',
            backgroundColor: isActive ? '#e6f2ff' : 'transparent',
          }}
          className="text-ellipsis overflow-hidden whitespace-nowrap flex gap-6 px-1 rounded items-center"
        >
          {file.name}
          {file.annotations && (
            <span className="flex items-center pr-4">
              {Object.entries(colorCounts).map(([color], index) => (
                <span key={index} className="flex items-center">
                  <span className="w-2 h-2 rounded-full -ml-1 p-1 border border-gray-200" style={{ backgroundColor: `${color}` }} />
                </span>
              ))}
              {totalExtra > 0 && <span className="ml-1 text-xs text-gray-500">+{totalExtra}</span>}
            </span>
          )}
        </span>
      </div>
      {isOpen && hasChildren && (
        <div>
          {file.children!.map((child, index) => (
            <FileTreeItem
              key={index}
              file={child}
              level={level + 1}
              isLast={index === file.children!.length - 1}
              onClick={onClick}
              isOpen={openFolders.has(child.id)}
              toggleFolder={toggleFolder}
            />
          ))}
        </div>
      )}
    </div>
  )
}


export default FileTree;