import React, { useEffect, useMemo, useRef, useState } from 'react'
import { NodeViewWrapper, NodeViewContent, NodeViewProps } from '@tiptap/react'
import { Link } from '@tanstack/react-router'
import { SquareArrowOutDownLeft } from 'lucide-react'
import { cn } from '@/lib/utils'
interface AiContentBlockAttributes {
  annotationIds: string[]
  repoId: string
  startLine: number
  endLine: number
  isClicked: boolean
  pageId: string
}

export const AiContentBlockView: React.FC<NodeViewProps> = ({ node, editor }) => {
  const attrs = node.attrs as AiContentBlockAttributes
  const [isHovering, setIsHovering] = useState(false)
  const [isClicked, setIsClicked] = useState(false)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: MouseEvent) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
      setIsClicked(false)
      editor?.commands.updateAttributes(node.type, { isClicked: false })
      // editor?.commands.updateAiContentBlockIsClicked(reference.annotationId, true);
    }
  }

  const getGlobalIndex = (annotationId: string) => {
    const allAnnotationIds: string[] = []
    editor.state.doc.descendants((node) => {
      if (node.type.name === 'aiContentBlock') {
        allAnnotationIds.push(...(node.attrs.annotationIds || []))
      }
    })
    return allAnnotationIds.indexOf(annotationId) + 1
  }

  // Sort annotation IDs based on their global index
  const sortedAnnotationIds = useMemo(() => {
    return [...attrs.annotationIds].sort((a, b) => getGlobalIndex(a) - getGlobalIndex(b))
  }, [attrs.annotationIds, editor.state.doc])

  useEffect(() => {
    if (attrs.isClicked) {
      setIsClicked(true)
    }
  }, [attrs.isClicked])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleClick = () => {
    if (!isClicked) {
      setIsClicked(true)
    }
  }

  return (
    <NodeViewWrapper
      as="div"
      ref={wrapperRef}
      className={`ai-content-block flex flex-col relative`}
      data-annotation-ids={JSON.stringify(attrs.annotationIds)}
      data-start-line={attrs.startLine}
      data-end-line={attrs.endLine}
      data-page-id={attrs.pageId}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={handleClick}
    >
      <div
        className={cn(`absolute -inset-x-2 -inset-y-0 pointer-events-none transition-colors duration-200 ease-in-out rounded border-2 border-transparent`, isHovering ? 'border-gray-50' : '', isClicked ? 'border-blue-500' : '',)}
      ></div>
      <NodeViewContent
        className={`ai-content relative z-10`}
      />

{sortedAnnotationIds.length > 0 && (
        <div className={`z-10 absolute bottom-0 right-0 flex gap-1 transition-opacity duration-300 ${isHovering ? 'opacity-100' : 'opacity-0'}`}>
          {sortedAnnotationIds.map((annotationId) => (
            <Link 
              key={annotationId}
              className="text-xs text-gray-600 bg-white px-1.5 py-0.5 cursor-pointer rounded-full border border-gray-300 hover:bg-gray-100 transition-colors duration-200 no-underline" // Added no-underline class
              to='/repos/$repoId/annotations/$annotationId' 
              params={{ repoId: attrs.repoId as string, annotationId }}
            >
              {getGlobalIndex(annotationId)}
            </Link>
          ))}
        </div>
      )}
    </NodeViewWrapper>
  )
}