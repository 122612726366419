import { createFileRoute } from '@tanstack/react-router'
import { client } from '@/lib/utils';
import { queryOptions, useMutation, useSuspenseQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect, useRef, useState, useCallback } from 'react'
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { Prism as SyntaxHighlighter, createElement } from 'react-syntax-highlighter'
import { Dialog, DialogContent, DialogDescription, DialogTitle } from '@/components/ui/dialog'
import Form from '@/components/Forms/Form'
import TextField from '@/components/Forms/TextField'
import { z } from 'zod'
import { schema } from '@readmewriter/db'
import { ChevronLeft, ChevronRight, Pencil, Trash, ArrowLeftFromLine, ArrowRightFromLine, Sparkles } from 'lucide-react'
import { BlockPicker } from 'react-color'
import { FixedSizeList as List, ListOnScrollProps } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Loader } from '@/components/ui/Loader'
import { usePage } from '@/components/PageProvider';

export const getFileData = async (fileId: string) => {
  try {
    const file = await client.api.files[':fileId'].$get({
      param: {
        fileId: fileId
      }
    })
    const data = await file.json()
    return data
  } catch (error) {
    console.error('Failed to fetch repo data:', error);
    throw error;
  }
}

export const fileDataQueryOptions = (fileId: string) => {
  return queryOptions({
    queryKey: ['fetch_file_data', fileId],
    queryFn: () => getFileData(fileId)
  })
}

function rowRenderer(
  { rows,
    stylesheet,
    useInlineStyles,
    getLineStyle,
    clickedAnnotation,
  }:
    {
      rows: Array<rendererNode>,
      stylesheet: { [key: string]: React.CSSProperties },
      useInlineStyles: boolean,
      getLineStyle: (lineNumber: number) => React.CSSProperties,
      clickedAnnotation: { start: number, end: number } | null,
    }
) {
  return ({ index, key, style }: { index: number, key: string, style: React.CSSProperties }) => {
    const lineStyle = getLineStyle(index + 1);
    const isFirstClickedRow = clickedAnnotation && (index + 1 === clickedAnnotation.start);
    const isLastClickedRow = clickedAnnotation && (index + 1 === clickedAnnotation.end);
    const className = isFirstClickedRow || isLastClickedRow ? "cursor-ns-resize" : "";

    const element = createElement({
      node: rows[index],
      stylesheet,
      style: { ...style, ...lineStyle },
      useInlineStyles,
      key,
    });
    return <div className={className} key={key}>{element}</div>;
  }
}

function virtualizedRenderer({ rowHeight = 24, getLineStyle, clickedAnnotation, listRef, handleScroll }: { rowHeight?: number, getLineStyle: (lineNumber: number) => React.CSSProperties, clickedAnnotation: { start: number, end: number } | null, listRef: React.RefObject<List>, handleScroll: (props: ListOnScrollProps) => void }) {
  
  return ({ rows, stylesheet, useInlineStyles }: { rows: Array<rendererNode>, stylesheet: { [key: string]: React.CSSProperties }, useInlineStyles: boolean }) => (
    <div style={{ height: '91vh' }}>
      <AutoSizer>
        {({ height, width }) => (
          <List
          ref={listRef}
          height={height}
          itemCount={rows.length}
          itemSize={rowHeight}
          width={width}
          onScroll={handleScroll}
          overscanCount={40}
        >
          {({ index, style }) => rowRenderer({ rows, stylesheet, useInlineStyles, getLineStyle, clickedAnnotation })({ index, key: index.toString(), style: {...style, height: rowHeight} })}
        </List>
        )}
      </AutoSizer>
    </div>
  );
}

const fetchAnnotations = async (fileId: string) => {
  try {
    const response = await client.api.files[':fileId'].annotations.$get({
      param: { fileId }
    });
    const annotations = await response.json();
    return annotations;
  } catch (error) {
    console.error('Failed to fetch annotations:', error);
    throw error;
  }
};

export const useFetchAnnotations = (fileId: string) => {
  return queryOptions({
    queryKey: ['fetch_annotations', fileId],
    queryFn: () => fetchAnnotations(fileId)
  })
}


export const FileRoute = () => {
  const { fileId, repoId } = Route.useParams()
  const { data } = useSuspenseQuery(fileDataQueryOptions(fileId))
  const { data: annotations } = useSuspenseQuery(useFetchAnnotations(fileId))
  const queryClient = useQueryClient();
  const listRef = useRef<List>(null);
  const syntaxHighlighterRef = useRef<HTMLDivElement>(null)

  const [scrollOffset, setScrollOffset] = useState(0);

  const handleScroll = useCallback(({ scrollOffset }: { scrollOffset: number }) => {
    setScrollOffset(scrollOffset);
  }, []);

  const [selectionBox, setSelectionBox] = useState<{ top: number, left: number, width: number, height: number } | null>(null);
  const [clickedAnnotation, setClickedAnnotation] = useState<{ color: string, start: number, end: number, id: string, description: string | null } | null>(null);

  const startPos = useRef<{ x: number, y: number } | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dragDirection, setDragDirection] = useState<'up' | 'down'>();
  const annotationControlRef = useRef<HTMLDivElement>(null);

  const handleMouseDown = useCallback((event: React.MouseEvent) => {
    setIsDragging(false)
    if (syntaxHighlighterRef.current) {
      const rect = syntaxHighlighterRef.current.getBoundingClientRect();

      const lineHeight = parseFloat(getComputedStyle(syntaxHighlighterRef.current).lineHeight || '20');
      const clickY = event.clientY - rect.top;

      const lineNumber = Math.floor((clickY + scrollOffset) / lineHeight) + 1;


      const foundRange = annotations?.find(({ startLine, endLine }) => lineNumber >= startLine && lineNumber <= endLine);
      if (foundRange) {
        setClickedAnnotation({
          id: foundRange.id,
          start: foundRange.startLine,
          end: foundRange.endLine,
          description: foundRange.description,
          color: foundRange.color as string
        });
      }


      startPos.current = {
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
      };

      setSelectionBox({
        top: startPos.current.y,
        left: startPos.current.x,
        width: 0,
        height: 0,
      });

    }
  }, [annotations, syntaxHighlighterRef]);

  const handleMouseMove = useCallback((event: React.MouseEvent) => {
    if (startPos.current && syntaxHighlighterRef.current) {
      const rect = syntaxHighlighterRef.current.getBoundingClientRect();
      const currentPos = {
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
      };
  
      const lineHeight = parseFloat(getComputedStyle(syntaxHighlighterRef.current).lineHeight || '20');
      // Calculate the current line more precisely
      const currentLine = Math.ceil((currentPos.y + scrollOffset) / lineHeight);
      const startLine = Math.ceil((startPos.current.y + scrollOffset) / lineHeight);
  
      if (!isDragging && (Math.abs(currentPos.x - startPos.current.x) > 5 || Math.abs(currentPos.y - startPos.current.y) > 5)) {
        setIsDragging(true);
        setDragDirection(startPos.current.y < currentPos.y ? 'down' : 'up');
      }
  
      if (isDragging) {
        // Update the clickedAnnotation with the precise start and end lines
        setClickedAnnotation(prev => prev ? { 
          ...prev, 
          start: Math.min(startLine, currentLine),
          end: Math.max(startLine, currentLine)
        } : prev);
  
        setSelectionBox({
          top: Math.min(startPos.current.y, currentPos.y),
          left: Math.min(startPos.current.x, currentPos.x),
          width: Math.abs(currentPos.x - startPos.current.x),
          height: Math.abs(currentPos.y - startPos.current.y),
        });
      }
    }
  }, [isDragging, scrollOffset, syntaxHighlighterRef]);

  const handleMouseUp = useCallback((event: React.MouseEvent) => {
    if (syntaxHighlighterRef.current && selectionBox) {
      const lineHeight = parseFloat(getComputedStyle(syntaxHighlighterRef.current).lineHeight || '20');
      let startLine = Math.ceil((selectionBox.top + scrollOffset) / lineHeight);
      let endLine = Math.ceil((selectionBox.top + selectionBox.height + scrollOffset) / lineHeight) - 1;
      // Ensure startLine is always less than or equal to endLine
      if (startLine > endLine) {
        [startLine, endLine] = [endLine, startLine];
      }

      if (isDragging) {
        if (clickedAnnotation && dragDirection === 'down') {
          // Optimistically update the annotations in the query cache
          queryClient.setQueryData(['fetch_annotations', fileId], (oldAnnotations: Array<{ id: string, startLine: number, endLine: number, description: string | null }>) => {
            return oldAnnotations.map(annotation => {
              if (annotation.id === clickedAnnotation.id) {
                return { ...annotation, endLine };
              }
              return annotation;
            });
          });
          updateAnnotation.mutate({
            id: clickedAnnotation.id,
            startLine: clickedAnnotation.start,
            endLine: endLine,
            description: clickedAnnotation.description || "",
            color: clickedAnnotation.color
          })
          setClickedAnnotation(prev => prev ? { ...prev, end: endLine } : null);
        } else {
          queryClient.setQueryData(['fetch_annotations', fileId], (oldAnnotations: Array<{ id: number, startLine: number, endLine: number, color: string }>) => {
            return [...oldAnnotations, { id: oldAnnotations.length + 1, startLine, endLine, color: '#FFFF00' }];
          });
          setIsDialogOpen(true)
        }
      } else {
        // Handle single click without dragging
        const rect = syntaxHighlighterRef.current.getBoundingClientRect();
        const lineNumber = Math.floor((event.clientY - rect.top) / lineHeight);
        const foundRange = annotations?.find(({ startLine, endLine }) => lineNumber >= startLine && lineNumber <= endLine);


        if (foundRange) {
          setClickedAnnotation({
            start: foundRange.startLine,
            end: foundRange.endLine,
            id: foundRange.id,
            description: foundRange.description,
            color: foundRange.color as string
          });


        }
      }


    }
    startPos.current = null;
    setSelectionBox(null);
    setIsDragging(false)
  }, [annotations, selectionBox, startPos, syntaxHighlighterRef, listRef]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Backspace' && clickedAnnotation) {
        queryClient.setQueryData(['fetch_annotations', fileId], (oldAnnotations: Array<{ id: string, startLine: number, endLine: number }>) => {
          return oldAnnotations.filter(annotation => annotation.id !== clickedAnnotation.id);
        });
        deleteAnnotation.mutate({
          id: clickedAnnotation.id
        })
        setClickedAnnotation(null);
      }

      if (event.key === "Escape" && !isDialogOpen && !formSubmitted) {
        setClickedAnnotation(null)
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [clickedAnnotation, formSubmitted, isDialogOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (clickedAnnotation && syntaxHighlighterRef.current && annotationControlRef.current) {
        const isClickInsideControlPanel = annotationControlRef.current.contains(event.target as Node);
        
        if (!isClickInsideControlPanel) {
          const rect = syntaxHighlighterRef.current.getBoundingClientRect();
          const clickY = event.clientY - rect.top;
          const lineHeight = parseFloat(getComputedStyle(syntaxHighlighterRef.current).lineHeight || '20');
          const clickedLine = Math.floor(clickY / lineHeight) + 1;

          const isClickInsideAnnotation = annotations?.some(
            ({ startLine, endLine }) => clickedLine >= startLine && clickedLine <= endLine
          );

          if (!isClickInsideAnnotation) {
            setClickedAnnotation(null);
          }
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [clickedAnnotation, syntaxHighlighterRef, annotations, annotationControlRef]);
const {selectedPage}= usePage()

  const getLineStyle = (lineNumber: number) => {
    if (clickedAnnotation && lineNumber >= clickedAnnotation.start && lineNumber <= clickedAnnotation.end) {
      let r = 0, g = 0, b = 0;


      if (clickedAnnotation.color?.length === 4) {
        r = parseInt(clickedAnnotation.color[1] + clickedAnnotation.color[1], 16);
        g = parseInt(clickedAnnotation.color[2] + clickedAnnotation.color[2], 16);
        b = parseInt(clickedAnnotation.color[3] + clickedAnnotation.color[3], 16);
      } else if (clickedAnnotation.color?.length === 7) {
        r = parseInt(clickedAnnotation.color[1] + clickedAnnotation.color[2], 16);
        g = parseInt(clickedAnnotation.color[3] + clickedAnnotation.color[4], 16);
        b = parseInt(clickedAnnotation.color[5] + clickedAnnotation.color[6], 16);
      }

      return {
        backgroundColor:`rgba(${r}, ${g}, ${b}, ${0.2})`,
        border: `1.4px solid ${clickedAnnotation.color}`,
        borderRadius: '4px'
      };
    }

    for (const { startLine, endLine, color } of annotations!) {
      let r = 0, g = 0, b = 0;


      if (color?.length === 4) {
        r = parseInt(color[1] + color[1], 16);
        g = parseInt(color[2] + color[2], 16);
        b = parseInt(color[3] + color[3], 16);
      } else if (color?.length === 7) {
        r = parseInt(color[1] + color[2], 16);
        g = parseInt(color[3] + color[4], 16);
        b = parseInt(color[5] + color[6], 16);
      }

      if (lineNumber >= startLine && lineNumber <= endLine) {
        return {
          backgroundColor: `rgba(${r}, ${g}, ${b}, ${0.2})`,
          border: `1px solid rgba(${r}, ${g}, ${b}, ${0.5})`,
          borderRadius: '4px'
        };
      }
    }

    return {};
  };

  const createAnnotation = useMutation({
    mutationFn: async (data: z.infer<typeof schema.createAnnotationSchema>) => {
      const res = await client.api.annotation.new.$post({
        json: {
          repoId: repoId,
          fileId: fileId,
          startLine: data.startLine,
          endLine: data.endLine,
          description: data.description,
          status: 'active'
        }
      })

      const annotation = await res.json()

      return annotation
    },
    onSuccess: (data) => {
      setIsDialogOpen(false)
      queryClient.invalidateQueries({ queryKey: ['repo_files', repoId] });
      queryClient.invalidateQueries({ queryKey: ['fetch_annotations', fileId] });

    }
  })

  const deleteAnnotation = useMutation({
    mutationFn: async (data: { id: string }) => {
      await client.api.annotation[':annotationId'].$delete({
        param: {
          annotationId: data.id
        }
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['repo_files', repoId] });
      queryClient.invalidateQueries({ queryKey: ['fetch_annotations', fileId] });
      queryClient.invalidateQueries({queryKey: ['single_annotation']});
      queryClient.invalidateQueries({queryKey: ['page_content', repoId, selectedPage?.id]}); 
    }
  })

  const updateAnnotation = useMutation({
    mutationFn: async (data: { id: string, startLine: number, endLine: number, description?: string, color: string }) => {
      await client.api.annotation[':annotationId'].$patch({
        param: {
          annotationId: data.id
        },
        json: {
          repoId: repoId,
          fileId: fileId,
          startLine: data.startLine,
          endLine: data.endLine,
          description: data.description,
          color: data.color
        }
      })
    },
    onSuccess: (data) => {
      setIsDialogOpen(false)
      setClickedAnnotation(null)
      queryClient.invalidateQueries({ queryKey: ['repo_files', repoId] });
      queryClient.invalidateQueries({ queryKey: ['fetch_annotations', fileId] });
    }
  })

  const handleDialogClose = (isOpen: boolean) => {
    if (!isOpen && !formSubmitted && !clickedAnnotation) {
      // Remove the last annotation created during the drag-select process
      queryClient.setQueryData(['fetch_annotations', fileId], (oldAnnotations: Array<{ id: string, startLine: number, endLine: number }>) => {
        return oldAnnotations.slice(0, -1);
      });
    }

    if (clickedAnnotation) {
      setClickedAnnotation(null)
    }
    setFormSubmitted(false); // Reset the form submission state
    setIsDialogOpen(isOpen);

  };

  const autoAnnotate = useMutation({
    mutationFn: async () => {
      await client.api.ai[':fileId']['auto-annotate'].$post({
        param: {
          fileId: fileId
        },
        json: {
          color: '#FFFF00'
        }
      })
    },
    onMutate: () => {
      setIsLoading(true)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['page_content', repoId, selectedPage?.id]});
      queryClient.invalidateQueries({queryKey: ['single_annotation']});
      queryClient.invalidateQueries({queryKey: ['repo_files', repoId]});
      queryClient.invalidateQueries({queryKey: ['repo_pages', repoId]});
      queryClient.removeQueries({queryKey: ['fetch_annotations', fileId]});
      localStorage.removeItem('repoSelections')

      setIsLoading(false)
    }
  })

  const [controlPosition, setControlPosition] = useState({ top: 0, left: 0 });
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    if (clickedAnnotation && syntaxHighlighterRef.current) {
      const lineHeight = parseFloat(getComputedStyle(syntaxHighlighterRef.current).lineHeight || '20');
      const rect = syntaxHighlighterRef.current.getBoundingClientRect();
      const top = rect.top + window.scrollY + (clickedAnnotation.end * lineHeight) + 20;
      const left = rect.left - 200;

      setControlPosition({ top, left });
    }
  }, [clickedAnnotation]);

  return (
    <div className="relative">
      {isLoading && <Loader label="I'm thinking about it..." />}
      <Dialog open={isDialogOpen} onOpenChange={handleDialogClose}>
        <DialogContent>
          <DialogTitle>{clickedAnnotation ? 'Edit annotation' : 'Create new annotation'}</DialogTitle>
          <DialogDescription>
            {annotations && annotations.length > 0 && (<Form
              onSubmit={async (values) => {
                try {
                  if (clickedAnnotation) {
                    await updateAnnotation.mutateAsync({
                      id: clickedAnnotation.id,
                      description: values.description,
                      startLine: values.startLine,
                      endLine: values.endLine,
                      color: clickedAnnotation.color
                    });
                  } else {
                    await createAnnotation.mutateAsync({
                      fileId: fileId,
                      repoId: repoId,
                      startLine: values.startLine,
                      endLine: values.endLine,
                      description: values.description,
                    });
                  }
                  setFormSubmitted(true);
                } catch (error) {
                  console.error(error)
                }

              }}
              initialValues={{
                description: clickedAnnotation ? clickedAnnotation.description || "" : annotations[annotations.length - 1].description || "",
                startLine: clickedAnnotation ? clickedAnnotation.start : annotations[annotations.length - 1].startLine,
                endLine: clickedAnnotation ? clickedAnnotation.end : annotations[annotations.length - 1].endLine
              }}
              schema={z.object({
                description: z.string(),
                startLine: z.number(),
                endLine: z.number()
              })}
              submitText={clickedAnnotation ? 'Update' : 'Submit'}
            >
              <TextField name="description" label="Description" />
              <div className='flex gap-2'>
                <TextField name="startLine" label="Start Line" disabled />
                <TextField name="endLine" label="End Line" disabled />
              </div>
            </Form>
            )}
          </DialogDescription>
        </DialogContent>
      </Dialog>
      <div className='flex flex-col'>
      <div className="sticky top-0 z-10 bg-white pb-4">
        <button 
          onClick={async () => autoAnnotate.mutateAsync()}
          className="disabled:opacity-50 border rounded p-2 bg-white text-gray-600 hover:text-gray-800 hover:bg-gray-100"
        >
          <Sparkles className="w-4 h-4" />
        </button>
      </div>
      <div className="flex-1 overflow-y-auto">
      <div
        ref={syntaxHighlighterRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        style={{ position: 'relative', userSelect: 'none' }}
      >
        <SyntaxHighlighter
          style={darcula}
          language={data.extension as string}
          showLineNumbers={true}
          PreTag="div"
          customStyle={{
            borderRadius: '10px'
          }}
          wrapLines={true}
          renderer={virtualizedRenderer({ getLineStyle, clickedAnnotation, listRef, handleScroll })}
        >

          {data.content || ''}
        </SyntaxHighlighter>

        {selectionBox && (
          <div
            style={{
              position: 'absolute',
              top: selectionBox.top,
              left: selectionBox.left,
              width: selectionBox.width,
              height: selectionBox.height,
              border: '2px dashed green',
              borderRadius: 4,
              backgroundColor: 'green',
              opacity: 0.4,
              pointerEvents: 'none',
            }}
          />
        )}
      </div>

      </div>
      </div>
      {clickedAnnotation && (
        <div 
          ref={annotationControlRef} 
          className='flex gap-4 absolute rounded-full border border-gray-200 bg-white p-2'
          style={{
            top: `${controlPosition.top}px`,
            left: `${controlPosition.left}px`,
            transform: 'translateY(8px)', // Add a small offset
            zIndex: 1000 // Ensure it's above other elements
          }}
          >

          <div className='flex flex-row items-center gap-1'>
            <label className='text-[9px] text-gray-700'><ArrowLeftFromLine className="w-4 h-4" /></label>
            <input type="number" value={clickedAnnotation.start} className="w-10 text-xs" onChange={(e) => {
              const newStart = Math.max(1, parseInt(e.target.value)); // Ensure the start line is at least 1
              setClickedAnnotation(prev => prev ? { ...prev, start: newStart } : prev);
              queryClient.setQueryData(['fetch_annotations', fileId], (oldAnnotations: Array<{ id: string, startLine: number, endLine: number, description: string }>) => {
                return oldAnnotations.map(annotation => annotation.id === clickedAnnotation.id ? { ...annotation, description: annotation.description, startLine: newStart } : annotation);
              });
              updateAnnotation.mutate({
                id: clickedAnnotation.id,
                startLine: newStart,
                endLine: clickedAnnotation.end,
                description: clickedAnnotation.description || "",
                color: clickedAnnotation.color
              })
            }} />
          </div>

          <div className='flex flex-row items-center gap-1'>
            <label className='text-[9px] text-gray-700'><ArrowRightFromLine className="w-4 h-4" /></label>
            <input type="number" value={clickedAnnotation.end} className="w-10 text-xs" onChange={(e) => {
              const totalLines = data.content?.split(/\r\n|\r|\n/).length; // Calculate total lines directly from data
              const newEnd = Math.min(Math.max(1, parseInt(e.target.value)), totalLines!);
              setClickedAnnotation(prev => prev ? { ...prev, end: newEnd } : prev);
              queryClient.setQueryData(['fetch_annotations', fileId], (oldAnnotations: Array<{ id: string, startLine: number, endLine: number, description: string }>) => {
                return oldAnnotations.map(annotation => annotation.id === clickedAnnotation.id ? { ...annotation, description: annotation.description, endLine: newEnd } : annotation);
              });
              updateAnnotation.mutate({
                id: clickedAnnotation.id,
                startLine: clickedAnnotation.start,
                endLine: newEnd,
                description: clickedAnnotation.description || "",
                color: clickedAnnotation.color
              })
            }} />
          </div>

          <div className="flex items-center relative">
            <button
              className="w-4 h-4 rounded-full"
              style={{ backgroundColor: `${clickedAnnotation.color}` }}
              onClick={() => {
                setIsColorPickerOpen(!isColorPickerOpen);
              }}
            ></button>
            {isColorPickerOpen && (
              <div style={{ position: 'absolute', zIndex: 10, top: '-220px', left: '0' }}>
                <BlockPicker
                  triangle='hide'
                  color={clickedAnnotation.color}
                  onChangeComplete={(color) => {
                    const newColor = color.hex;
                    setClickedAnnotation(prev => prev ? { ...prev, color: newColor } : prev);
                    queryClient.setQueryData(['fetch_annotations', fileId], (oldAnnotations: Array<{ id: string, startLine: number, endLine: number, description: string, color: string }>) => {
                      return oldAnnotations.map(annotation => annotation.id === clickedAnnotation.id ? { ...annotation, color: newColor } : annotation);
                    });
                    queryClient.setQueryData(['repo_files', repoId], (oldFiles: Array<{ id: string, name: string, path: string, type: string, annotations: Array<{ id: string, color: string }> }>) => {
                      return oldFiles.map(file => {
                        if (file.id === fileId) {
                          return {
                            ...file,
                            annotations: file.annotations.map(annotation =>
                              annotation.id === clickedAnnotation.id ? { ...annotation, color: newColor } : annotation
                            )
                          };
                        }
                        return file;
                      });
                    });
                    setClickedAnnotation(null);
                    setIsColorPickerOpen(false);
                    updateAnnotation.mutate({
                      id: clickedAnnotation.id,
                      startLine: clickedAnnotation.start,
                      endLine: clickedAnnotation.end,
                      description: clickedAnnotation.description || "",
                      color: newColor
                    }, {
                      onSuccess: () => {
                        queryClient.invalidateQueries({ queryKey: ['repo_files', repoId] });
                      }
                    });
                  }}

                />
              </div>
            )}
          </div>

          <div>
            <button onClick={async () => {
              if (clickedAnnotation) {
                setIsDialogOpen(true)
              }
            }} className='disabled:opacity-50 text-gray-600 hover:text-gray-700'><Pencil className="w-4 h-4" /></button>
          </div>

          <div>
            <button onClick={async (e) => {
              e.preventDefault()
              if (clickedAnnotation) {
                await deleteAnnotation.mutateAsync({
                  id: clickedAnnotation.id
                })
                setClickedAnnotation(null)
                queryClient.setQueryData(['fetch_annotations', fileId], (oldAnnotations: Array<{ id: string, startLine: number, endLine: number }>) => {
                  return oldAnnotations.filter(annotation => annotation.id !== clickedAnnotation.id);
                });
              }
            }} className='disabled:opacity-50 text-gray-600 hover:text-gray-700'><Trash className="w-4 h-4" /></button>
          </div>

          <button
            className='text-xs'
            onClick={() => {
              if (clickedAnnotation) {
                const currentIndex = annotations?.findIndex(ann => ann.id === clickedAnnotation.id);
                const prevIndex = currentIndex! - 1;
                if (prevIndex >= 0) {
                  const annotation = annotations![prevIndex];
                  setClickedAnnotation({
                    id: annotation.id,
                    start: annotation.startLine,
                    end: annotation.endLine,
                    description: annotation.description || "",
                    color: annotation.color as string
                  });
                }
              }
            }}
          >
            <ChevronLeft className="w-4 h-4" />
          </button>

          <button
            className='text-xs'
            onClick={() => {
              if (clickedAnnotation) {
                const currentIndex = annotations!.findIndex(ann => ann.id === clickedAnnotation.id);
                const nextIndex = currentIndex + 1;
                if (nextIndex < annotations!.length) {
                  const annotation = annotations![nextIndex];
                  setClickedAnnotation({
                    id: annotation.id,
                    start: annotation.startLine,
                    end: annotation.endLine,
                    description: annotation.description || "",
                    color: annotation.color as string
                  });
                }
              }
            }}
          >
            <ChevronRight className="w-4 h-4" />
          </button>

        </div>
      )}
      
    </div>
  )
}

export const Route = createFileRoute('/_repos/repos/$repoId/files/$fileId/')({
  loader: async (opts) => {
    const fileData = await opts.context.queryClient.ensureQueryData(fileDataQueryOptions(opts.params.fileId))
    return { fileData }
  },
  component: FileRoute
})

