import { createFileRoute } from '@tanstack/react-router'
import { client } from '@/lib/utils'
import { queryOptions, useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query'
import Form from '@/components/Forms/Form'
import TextField from '@/components/Forms/TextField'
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { BlockPicker } from 'react-color'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { SquareArrowOutUpRight } from 'lucide-react'
import { useEditor } from '@/components/Editor/EditorContext'
import { usePage } from '@/components/PageProvider'
import { z } from 'zod'
import { schema } from '@readmewriter/db'

export const fetchSingleAnnotation = async (annotationId: string) => {
  try {
    const annotation = await client.api.annotation[':annotationId'].$get({
      param: {
        annotationId: annotationId
      },
      query: {
        status: 'active'
      }
    })
    const data = await annotation.json()
    return data
  } catch (error) {
    console.error('Failed to fetch single annotation:', error);
    throw error;
  }
}


export const annotationQueryOptions = (annotationId: string) => {
  return queryOptions({
    queryKey: ['single_annotation', annotationId],
    queryFn: () => fetchSingleAnnotation(annotationId),
    refetchOnMount: true
  })
}

const Annotation = () => {
  const { annotationId } = Route.useParams()
  const annotation = useSuspenseQuery(annotationQueryOptions(annotationId))
  const queryClient = useQueryClient();
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const form = useForm()
  const { editor } = useEditor();
  const { setSelectedPage } = usePage();




  const updateAnnotation = useMutation({
    mutationFn: async (data: { id: string, description: string, color: string, startLine: number, endLine: number }) => {
      await client.api.annotation[':annotationId'].$patch({
        param: {
          annotationId: data.id
        },
        json: {
          description: data.description,
          color: data.color,
          repoId: annotation.data.repoId,
          fileId: annotation.data.fileId,
          startLine: Number(data.startLine),
          endLine: Number(data.endLine)
        }
      })
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['single_annotation', annotationId] });
      queryClient.invalidateQueries({ queryKey: ['repo_files', annotation.data.repoId] });
      queryClient.invalidateQueries({ queryKey: ['fetch_annotations', annotation.data.fileId] });
    }
  })

  const lines = annotation.data.file.content?.split('\n');
  const highlightedContent = lines?.slice(annotation.data.startLine - 1, annotation.data.endLine).join('\n');


  const handleSetSelectedPage = async (reference: z.infer<typeof schema.referenceWithPageSchema>) => {
    const actions = [
      // Set the selected page
      () => setSelectedPage({
        ...reference.page,
        createdAt: new Date(reference.page.createdAt),
        updatedAt: new Date(reference.page.updatedAt),
        lastPublishedAt: reference.page.lastPublishedAt ? new Date(reference.page.lastPublishedAt) : null
      }),
      // Perform editor actions
      () => {
        if (editor) {
          editor.commands.focus();
          const lines = editor.state.doc.textBetween(0, editor.state.doc.content.size, "\n", "\n").split("\n");
          const endLinePos = lines.slice(0, reference.endLine).join("\n").length + reference.endLine;
          const endPos = editor.state.doc.resolve(endLinePos).end();

          editor.commands.setTextSelection({
            from: endPos,
            to: endPos
          });
          const node = editor.state.doc.nodeAt(endPos);
          if (node) {
            editor.commands.updateAttributes(node.type, { isClicked: true })
          }
          editor.commands.updateAiContentBlockIsClicked([reference.annotationId], true);
        }
      }
    ];

    for (const action of actions) {
      await new Promise(resolve => {
        action();
        // Use requestAnimationFrame to ensure the DOM has updated
        requestAnimationFrame(resolve);
      });
    }
  }

  return (
    <div className='flex flex-col gap-8'>
      <div
        style={{ position: 'relative' }}
      >
        <SyntaxHighlighter
          style={darcula}
          language={annotation.data.file.extension as string}
          showLineNumbers={true}
          startingLineNumber={annotation.data.startLine}
          PreTag="div"
          customStyle={{
            borderRadius: '10px',
          }}
          wrapLines={true}
        >
          {highlightedContent as string}
        </SyntaxHighlighter>
      </div>

      <div className='flex items-center gap-2'>
        {annotation.data.status === 'active' && annotation.data.references && annotation.data.references.length > 0 && <span className='bg-green-200 border border-green-300 text-green-600 text-xs px-2 py-1 rounded-md'>Live</span>}
        {annotation.data.status === 'archived' && <span className='bg-gray-200 border border-gray-300 text-gray-600 text-xs px-2 py-1 rounded-md'>Archived</span>}
      </div>

      {/* <div className='flex flex-col gap-2'>
        <div><span>Type:</span> <span>{annotation.data.file.extension}</span></div>
        <div><span>File:</span> <span>{annotation.data.file.name}</span></div>
        {annotation.data.file.extension === 'json' && <div><span>Key:</span> <span>dev</span> <span>Value:</span> <span>bun run src/index.ts</span></div>}
      </div> */}

      <Form
        key={annotation.data.id}
        onSubmit={async (values) => {
          try {
            await updateAnnotation.mutateAsync({
              id: annotation.data.id,
              description: values.description,
              color: values.color,
              startLine: values.startLine,
              endLine: values.endLine
            })
          } catch (error) {
            console.error('Failed to update annotation:', error);
          }
        }}
        submitText='Update'
        initialValues={{
          description: annotation.data.description || "",
          startLine: annotation.data.startLine || "",
          endLine: annotation.data.endLine || "",
          color: annotation.data.color || ""
        }}
      >
        <TextField name="description" label="Description" />
        <div className='flex items-center gap-2 w-full'>
          <TextField name="startLine" type="number" label="Start Line" />
          <TextField name="endLine" type="number" label="End Line" />
        </div>
        <div className='flex flex-col gap-2 w-full'>
          <span className="text-sm font-medium leading-none">Color</span>
          <button
            className="p-1 rounded"
            style={{ backgroundColor: `${form.getValues('color') || annotation.data.color}` }}
            onClick={(e) => {
              e.preventDefault();
              setIsColorPickerOpen(!isColorPickerOpen);
            }}>{form.getValues('color') || annotation.data.color}</button>
          {isColorPickerOpen && (
            <div style={{ position: 'absolute', zIndex: 10, top: '22rem' }}>
              <BlockPicker
                triangle='hide'
                color={form.getValues('color') as string || annotation.data.color as string}
                onChangeComplete={(color) => {
                  const newColor = color.hex;
                  form.setValue('color', newColor);
                  setIsColorPickerOpen(false);
                }}
              />
            </div>
          )}
        </div>
      </Form>

      <div className='flex flex-col gap-2'>
        <h3 className='text-lg font-medium leading-none'>References</h3>
        <hr />
        <div className="flex flex-col gap-2">
          {annotation.data.references && annotation.data.references.length > 0 && annotation.data.references.map((reference) => (
            <div className="border p-2 rounded flex justify-between items-center" key={reference.id}>
              <div className="text-sm text-gray-600">Page <span className="font-bold text-gray-800">{reference.page.title}</span> - Lines <span className="font-bold text-gray-800">{reference.startLine}</span> to <span className="font-bold text-gray-800">{reference.endLine}</span></div>
              <div><SquareArrowOutUpRight className="w-4 h-4 cursor-pointer" onClick={async (e) => {
                e.preventDefault();
                await handleSetSelectedPage(reference);
              }} /></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}



export const Route = createFileRoute('/_repos/repos/$repoId/annotations/$annotationId/')({
  loader: async (opts) => {
    await opts.context.queryClient.ensureQueryData(annotationQueryOptions(opts.params.annotationId))
  },
  component: Annotation
})