import { StrictMode } from 'react';
import { RouterProvider, createRouter, ErrorComponent } from '@tanstack/react-router'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
// Import the generated route tree
import { routeTree } from './routeTree.gen'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { PersistQueryClientProvider, PersistedClient, Persister, persistQueryClient } from '@tanstack/react-query-persist-client';
import { AuthProvider, useAuth } from './lib/auth';
import { EditorProvider } from './components/Editor/EditorContext';
import { get, set, del } from 'idb-keyval'
import { PageProvider } from './components/PageProvider';


export function createIDBPersister(idbValidKey: IDBValidKey = 'reactQuery') {
  return {
    persistClient: async (client: PersistedClient) => {
      await set(idbValidKey, client)
    },
    restoreClient: async () => {
      return await get<PersistedClient>(idbValidKey)
    },
    removeClient: async () => {
      await del(idbValidKey)
    },
  } as Persister
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 60 * 24, // 24 hours
      staleTime: 2000,
      retry: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  },
})

const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
})

const idbPersister = createIDBPersister('reactQuery')
persistQueryClient({
  queryClient,
  persister: idbPersister,
})
persistQueryClient({
  queryClient,
  persister: localStoragePersister,
})

// Create a new router instance
const router = createRouter({
  routeTree,
  context: {
    queryClient,
    auth: undefined!
  },
  defaultErrorComponent: ({ error }) => <ErrorComponent error={error} />,
  defaultPreloadStaleTime: 0
})

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

function InnerApp() {
  const auth = useAuth()
  return <RouterProvider router={router} context={{ auth }} />
}

export const App = () => {
  return (
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <PersistQueryClientProvider
          client={queryClient}
          persistOptions={{
            persister: idbPersister,
            dehydrateOptions: {
              shouldDehydrateQuery(query) {
                // Add conditions for multiple queries
                
                const queriesToDehydrate = ['billing_plan', 'current_user', 'products'];
                const queryKey = query.queryKey[0] as string;
                return !queriesToDehydrate.includes(queryKey);
              }
            }
          }}
        >
          <PersistQueryClientProvider
            client={queryClient}
            persistOptions={{
              persister: localStoragePersister,
              dehydrateOptions: {
                shouldDehydrateQuery(query) {
                  const queriesToPersist = ['current_user', 'products'];
                  const queryKey = Array.isArray(query.queryKey) ? query.queryKey[0] : query.queryKey;
                  return queriesToPersist.some(key => 
                    typeof queryKey === 'string' && queryKey.startsWith(key)
                  );
                }
              }
            }}
          >
          <AuthProvider>
            <PageProvider>
              <EditorProvider>
                <InnerApp />
              </EditorProvider>
            </PageProvider>
          </AuthProvider>
          </PersistQueryClientProvider>
        </PersistQueryClientProvider>
      </QueryClientProvider>
    </StrictMode>
  );
};
