import { Variables } from "@readmewriter/api";
import { queryOptions, useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { client } from "./utils";
import { usePostHog } from 'posthog-js/react'

export interface IAuthContext extends Variables {
  billingPlan?: {
    hasBillingPlan: boolean,
    planName?: string,
    status?: string | null,
    maxRepos?: number,
    aiCalls?: number,
    expired?: boolean,
    expiresAt?: Date | null,
  }
}

export const getBillingPlan = async (workspaceId: string) => {
  try {
    const plan = await client.api.workspaces[":workspaceId"].plan.$get({
      param: {
        workspaceId: workspaceId
      }
    })
    const data = await plan.json();
    return data;
  } catch (error) {
    console.error('Failed to fetch billing plan:', error);
    throw error;
  }

}

export const billingPlanQueryOptions = (workspaceId: string) => {
  return queryOptions({
    queryKey: ['billing_plan', workspaceId],
    queryFn: () => getBillingPlan(workspaceId),
  })
}


export const getCurrentUser = async () => {
  try {
    const user = await client.api.auth.me.$get();
    const data = await user.json();

    return data;
  } catch (error) {
    console.error('Failed to fetch user data:', error);
    throw error;
  }
}

export const userQueryOptions = () => {
  return queryOptions({
    queryKey: ['current_user'],
    queryFn: () => getCurrentUser(),
  })
}

const AuthContext = React.createContext<IAuthContext>({ session: null, user: null, billingPlan: { hasBillingPlan: false, planName: '', status: '' } })

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const posthog = usePostHog()
  const { data: user, refetch } = useQuery({ ...userQueryOptions(), enabled: false })

  const { data: billingPlan } = useQuery({ ...billingPlanQueryOptions(user?.session.current_workspace_id!), enabled: !!user })

  useEffect(() => {
    if (user === undefined) {
      refetch();
    }

    if (user) {
      posthog.identify(
        user.user.id,  
        { email: user.user.email, username: user.user.username } 
      );
    }
  }, [user, refetch]);

  const transformedUser = user ? {
    ...user,
    session: {
      ...user.session,
      expiresAt: new Date(user.session.expiresAt)
    },
  } : null;


  return (
    <AuthContext.Provider value={{
      session: transformedUser?.session ?? null,
      user: transformedUser?.user ?? null,
      billingPlan: {
        ...billingPlan,
        hasBillingPlan: billingPlan?.status === 'active' ? true : false,
        expiresAt: billingPlan?.expiresAt ? new Date(billingPlan.expiresAt) : null
      }
    }}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const context = React.useContext(AuthContext)

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}