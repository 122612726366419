import { useMutation } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { client } from '@/lib/utils'
import { Github } from 'lucide-react'

const Login = () => {

  const mutation = useMutation({
    mutationFn: () => {
      return client.api.auth.login.github.$get({
        query: {
          redirect_uri: `${import.meta.env.VITE_APP_URL}`
        }
      })
    }
  })
  
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <button className="border border-gray-300 rounded-md px-4 py-2 text-sm flex gap-2 items-center font-medium text-gray-500 hover:bg-gray-50" onClick={async () => {
        const getUrl = await mutation.mutateAsync()
        const response = await getUrl.json()
        window.location.href = response.url
      }}>
        <Github className="border border-gray-300 rounded-full p-1" />
        Log in with Github
      </button>

      <div className="pt-12">
        <img src="/ball.png" alt="logo" className="w-10 h-10" />
      </div>
    </div>
  )
}


export const Route = createFileRoute('/login')({
  component: Login
})