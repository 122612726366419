import { createRootRouteWithContext, Outlet } from '@tanstack/react-router'
import { QueryClient } from '@tanstack/react-query'
import { Variables } from '@readmewriter/api'



export const Route = createRootRouteWithContext<{
  queryClient: QueryClient,
  auth: Variables
}>()({
  component: () => {
    return (
      <Outlet />
    )
  },
})
