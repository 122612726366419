import React, { createContext, useContext, useEffect, useState } from 'react';
import { z } from 'zod';
import { schema } from "@readmewriter/db";


type PageContextType = {
  selectedPage: z.infer<typeof schema.selectPageSchema> | null;
  setSelectedPage: (page: z.infer<typeof schema.selectPageSchema> | null) => void;

};

const PageContext = createContext<PageContextType | undefined>(undefined);

export const PageProvider: React.FC<{ 
  children: React.ReactNode,
  initialPage?: z.infer<typeof schema.selectPageSchema> | null,
}> = ({ children, initialPage = null }) => {
  const [selectedPage, setSelectedPage] = useState<z.infer<typeof schema.selectPageSchema> | null>(initialPage);

  return (
    <PageContext.Provider value={{ 
      selectedPage, 
      setSelectedPage, 
    }}>
      {children}
    </PageContext.Provider>
  );
};

export const usePage = (initialPage?: z.infer<typeof schema.selectPageSchema> | null) => {
  const context = useContext(PageContext);
  if (context === undefined) {
    throw new Error('usePage must be used within a PageProvider');
  }

  useEffect(() => {
    if (initialPage && !context.selectedPage) {
      context.setSelectedPage(initialPage);
    }
  }, [initialPage, context.selectedPage]);

  return context;
};