import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "@/components/ui/resizable"
import { schema } from "@readmewriter/db"
import { z } from "zod"
import FileTree from "./ui/file-tree"
import { Link, useNavigate, useLocation } from "@tanstack/react-router"
import { Save, CloudUpload, ArrowLeft, Cog, Loader, Check, Library, FileIcon, Plus, Ellipsis, Trash2, FileDown, Clipboard } from 'lucide-react'
import { RichTextField } from "./Editor/RichTextField"
import { cn } from "@/lib/utils"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { queryOptions, useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { client } from "@/lib/utils"
import AnnotationListSidebar from "./ui/AnnotationListSidebar"
import { SettingsDialog } from "./Settings"
import { FileTreeProvider } from '@/components/ui/file-tree';
import { useEditor as useEditorContext } from './Editor/EditorContext';
import { Form } from '@/components/Forms/Form'
import { TextField } from '@/components/Forms/TextField'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "./ui/dropdown-menu"
import { usePage } from "./PageProvider"

interface RepoSelection {
  annotationId: string | null;
  fileId: string | null;
}

interface StoredSelections {
  [repoId: string]: RepoSelection;
}
export const getPageContent = async (repoId: string, pageId: string) => {
  try {
    const pages = await client.api.readme[':repoId'][':pageId'].$get({
      param: {
        repoId: repoId,
        pageId: pageId
      }
    })
    return pages.text()
  } catch (error) {
    console.error('Failed to fetch repo pages:', error);
    throw error;
  }
}




export const pageQueryOptions = (repoId: string, pageId: string) => {
  return queryOptions({
    queryKey: ['page_content', repoId, pageId],
    queryFn: () => getPageContent(repoId, pageId),
    staleTime: Infinity,
  })
}


export const SidebarLayout = ({
  defaultLayout = [20, 36, 44],
  files,
  children,
  repoId,
  pages,
  repos,
  isSettingsOpen,
}: {
  defaultLayout?: number[],
  files?: (z.infer<typeof schema.selectFileSchema> & { annotations: z.infer<typeof schema.selectAnnotationSchema>[] })[],
  children: React.ReactNode,
  repoId?: string,
  pages?: z.infer<typeof schema.selectPageSchema>[],
  repos?: z.infer<typeof schema.selectRepoSchema>[],
  isSettingsOpen?: boolean,
}) => {
  const { editor } = useEditorContext()
  const navigate = useNavigate()
  const location = useLocation()
  const [saveStatus, setSaveStatus] = useState<'idle' | 'saving' | 'success'>('idle');
  const [uploadStatus, setUploadStatus] = useState<'idle' | 'uploading' | 'success'>('idle');
  const initialPage = location.pathname.includes('/annotations/') ? "singleAnnotation" : "fileTree";
  const [currentPage, setCurrentPage] = useState<"singleAnnotation" | "fileTree">(initialPage);
  const isGrayBackground = location.pathname.match(/^\/repos\/[^/]+$/);
  const [newPageDialogOpen, setNewPageDialogOpen] = useState(false)
  const queryClient = useQueryClient();

  const [settingsOpen, setSettingsOpen] = useState(isSettingsOpen)
  const { selectedPage, setSelectedPage } = usePage(pages && pages.length > 0 ? pages[0] : null);



  const pageQuery = useQuery(
    pageQueryOptions(repoId as string, selectedPage?.id as string)
  );

  // This is a workaround to prevent the page content from being refetched on every render. The staleTime is set to Infinity, but want to refetch when the browser is reloaded.
  const isFirstRender = useRef(true);
  useLayoutEffect(() => {
    if (isFirstRender.current && repoId) {
      queryClient.invalidateQueries({
        queryKey: ['page_content', repoId],
      });
      isFirstRender.current = false;
    }
  }, [repoId, queryClient]);
  // // // // // // // //


  const handleNewPageDialogOpenChange = (newOpen: boolean) => {
    setNewPageDialogOpen(newOpen)
  }


  const [{ selectedAnnotationId, selectedFileId }, setSelectedIds] = useState(() => {
    const storedSelectionsString = localStorage.getItem('repoSelections');
    if (storedSelectionsString) {
      const storedSelections: StoredSelections = JSON.parse(storedSelectionsString);
      const currentRepoSelection = storedSelections[repoId!] || { annotationId: null, fileId: null };
      return {
        selectedAnnotationId: currentRepoSelection.annotationId,
        selectedFileId: currentRepoSelection.fileId
      };
    }
    return { selectedAnnotationId: null, selectedFileId: null };
  });

  useEffect(() => {
    const handleStorageChange = () => {
      const storedSelectionsString = localStorage.getItem('repoSelections');
      if (storedSelectionsString) {
        const storedSelections: StoredSelections = JSON.parse(storedSelectionsString);
        const currentRepoSelection = storedSelections[repoId!] || { annotationId: null, fileId: null };
        setSelectedIds({
          selectedAnnotationId: currentRepoSelection.annotationId,
          selectedFileId: currentRepoSelection.fileId
        });
      } else {
        setSelectedIds({ selectedAnnotationId: null, selectedFileId: null });
      }
    };
  
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [repoId]);


  const createPage = useMutation({
    mutationFn: async (values: z.infer<typeof schema.createPageSchema>) => {
      const response = await client.api.readme[":repoId"].pages.$post({
        param: {
          repoId: repoId as string
        },
        json: values
      })
      return response.json()
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['repo_pages', repoId] })
      setNewPageDialogOpen(false)
    }
  })

  const updateStoredSelection = (annotationId: string | null, fileId: string | null) => {
    const storedSelectionsString = localStorage.getItem('repoSelections');
    let storedSelections: StoredSelections = {};
    if (storedSelectionsString) {
      storedSelections = JSON.parse(storedSelectionsString);
    }
    storedSelections[repoId!] = { annotationId, fileId };
    localStorage.setItem('repoSelections', JSON.stringify(storedSelections));
    setSelectedIds({ selectedAnnotationId: annotationId, selectedFileId: fileId });
  };

  useEffect(() => {
    if (editor && !editor.isFocused) {
      editor.chain().scrollIntoView().focus('end').run();
    }
  }, [editor, location]);



  const updatePage = useMutation({
    mutationFn: async (content: string) => {
      await client.api.readme[":repoId"].save[":pageId"].$patch({
        param: {
          repoId: repoId as string,
          pageId: selectedPage?.id as string
        },
        json: {
          content: content
        }
      })
    },
    onMutate: () => {
      setSaveStatus('saving');
    },
    onSuccess: () => {
      setTimeout(() => {
        setSaveStatus('success');
        queryClient.invalidateQueries({ queryKey: ['repo_pages', repoId] })
        queryClient.invalidateQueries({ queryKey: ['single_annotation'] });
      }, 1000);
    },
    onSettled: () => {
      setTimeout(() => {
        setSaveStatus('idle');
      }, 2000);
    }
  })

  const deletePage = useMutation({
    mutationFn: async (values: { repoId: string, pageId: string }) => {
      await client.api.readme[":repoId"].pages[":pageId"].$delete({
        param: {
          repoId: values.repoId as string,
          pageId: values.pageId as string
        }
      })
    },
    onSuccess: (_, values) => {
      queryClient.invalidateQueries({ queryKey: ['repo_pages', repoId] })
      queryClient.invalidateQueries({ queryKey: ['page_content', repoId, selectedPage?.id] })

    }
  })

  const uploadPage = useMutation({
    mutationFn: async () => {
      await client.api.readme[":repoId"].upload[":pageId"].$post({
        param: {
          repoId: repoId as string,
          pageId: selectedPage?.id as string
        }
      })
    },
    onMutate: () => {
      setUploadStatus('uploading');
    },
    onSuccess: () => {
      setTimeout(() => {
        setUploadStatus('success');
        queryClient.invalidateQueries({ queryKey: ['repo_pages', repoId] })
      }, 1000);
    },
    onSettled: () => {
      setTimeout(() => {
        setUploadStatus('idle');
      }, 2000);
    }
  })


  const getFirstAnnotationId = () => {
    for (const file of files || []) {
      if (file.annotations.length > 0) {
        return file.annotations[0].id;
      }
    }
    return null;
  };

  const getFirstBlobFileId = () => {
    let firstBlobFileId = null;

    for (const file of files || []) {
      if (file.fileType === 'blob') {
        if (file.annotations.length > 0) {
          return file.id; // Return immediately if blob file has annotations
        }
        if (!firstBlobFileId) {
          firstBlobFileId = file.id; // Store the first blob file ID
        }
      }
    }

    return firstBlobFileId;
  };

  useEffect(() => {
    const handleKeyDown = async (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 's') {
        event.preventDefault();
        await updatePage.mutateAsync(pageQuery.data || "");
      }
      if ((event.metaKey || event.ctrlKey) && event.key === 'u') {
        event.preventDefault();
        await updatePage.mutateAsync(pageQuery.data || "");
        await uploadPage.mutateAsync();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [pageQuery, updatePage]);

  useEffect(() => {
    if (location.pathname.includes('/annotations/')) {
      setCurrentPage("singleAnnotation");
    } else if (location.pathname.includes('/files/')) {
      setCurrentPage("fileTree");
    }
  }, [location.pathname]);



  const handleSettingsOpenChange = (newOpen: boolean) => {
    if (isSettingsOpen) {
      // Prevent closing if on billing tab and plan is canceled
      return
    }
    setSettingsOpen(newOpen)
  }

  if (isSettingsOpen) {
    return (
      <div className="h-screen w-screen bg-white">
        <Dialog open={true} onOpenChange={handleSettingsOpenChange}>
          <SettingsDialog isSettingsOpen={true} />
        </Dialog>
      </div>
    );
  }


  return (
    <FileTreeProvider files={files}>
      <ResizablePanelGroup
        direction="horizontal"
        onLayout={(sizes: number[]) => {
          document.cookie = `react-resizable-panels:layout=${JSON.stringify(sizes)}`
        }}
        className="h-full items-stretch"
      >
        <ResizablePanel
          defaultSize={defaultLayout[0]}
          minSize={12}
          maxSize={20}
        >
          <div className="h-screen flex flex-col">
            <div className="sticky top-0 border-b border-gray-200 bg-opacity-70 px-4 py-1 z-10 bg-white flex gap-4 flex-row justify-between">
              {!files ? <div className="p-[13px]" /> : <Link
                to={'/'}
                className="disabled:opacity-50 border rounded p-1 bg-white text-gray-600 hover:text-gray-800 hover:bg-gray-100"
              >
                <span className="flex items-center gap-2 text-xs"><ArrowLeft className="w-4 h-4" /></span>
              </Link>}

              <div className="flex flex-row gap-2">
                {files ? (
                  <button className={cn("disabled:opacity-50 border rounded p-1 bg-white text-gray-600 hover:text-gray-800 hover:bg-gray-100", currentPage === "fileTree" ? "bg-gray-100" : "")} onClick={async () => {
                    setCurrentPage("fileTree");
                    const fileIdToUse = selectedFileId || getFirstBlobFileId();
                    if (fileIdToUse) {
                      updateStoredSelection(selectedAnnotationId, fileIdToUse);
                      navigate({ to: `/repos/$repoId/files/$fileId`, params: { repoId: repoId as string, fileId: fileIdToUse } })
                    }
                  }}>
                    <FileIcon className="w-4 h-4" />
                  </button>
                ) : null}

                {files ? (
                  <button className={cn("disabled:opacity-50 border rounded p-1 bg-white text-gray-600 hover:text-gray-800 hover:bg-gray-100", currentPage === "singleAnnotation" ? "bg-gray-100" : "")} onClick={async () => {
                    setCurrentPage("singleAnnotation");
                    const annotationIdToUse = getFirstAnnotationId();
                    if (annotationIdToUse) {
                      updateStoredSelection(annotationIdToUse, selectedFileId);
                      navigate({ to: `/repos/$repoId/annotations/$annotationId`, params: { repoId: repoId as string, annotationId: annotationIdToUse } })
                    } else {
                      // Handle the case when there are no annotations
                      console.log('No annotations available');
                      // You might want to show a message to the user or navigate to a different page
                    }
                  }}>
                    <Library className="w-4 h-4" />
                  </button>
                ) : null}


              </div>
            </div>
            <div className="flex-1 overflow-y-auto">
              {files ? (
                <div className="flex flex-col h-full">
                  <div className="flex-1 overflow-y-auto py-4">
                    <ResizablePanelGroup direction="vertical">
                      <ResizablePanel defaultSize={70}>
                        <div className="h-full overflow-y-auto pb-4 px-4">
                          {currentPage === "singleAnnotation" ? (
                            <AnnotationListSidebar
                              annotations={files?.flatMap(file => file.annotations)}
                              onAnnotationClick={(annotation) => {
                                updateStoredSelection(annotation.id, selectedFileId);
                                navigate({ to: `/repos/${repoId}/annotations/${annotation.id}` })
                              }}
                            />
                          ) : (
                            <FileTree
                              files={files}
                              onFileClick={async (file) => {
                                updateStoredSelection(selectedAnnotationId, file.id);
                                navigate({ to: `/repos/${repoId}/files/${file.id}` })
                              }}
                            />
                          )}
                        </div>
                      </ResizablePanel>
                      <ResizableHandle withHandle />
                      <ResizablePanel defaultSize={30}>
                        <div className="flex py-2 flex-col gap-2 px-2">
                          <div className="flex flex-row gap-2 justify-between px-2">
                            <h3 className="text-xs text-gray-400">Pages</h3>
                            <Dialog open={newPageDialogOpen} onOpenChange={handleNewPageDialogOpenChange}>
                              <DialogTrigger asChild>
                                <button>
                                  <Plus className="w-4 h-4" />
                                </button>
                              </DialogTrigger>
                              <DialogContent>
                                <DialogHeader>
                                  <DialogTitle>New Page</DialogTitle>
                                </DialogHeader>
                                <Form
                                  submitText="Create"
                                  schema={z.object({
                                    title: z.string().min(1)
                                  })}
                                  onSubmit={async (values) => {
                                    await createPage.mutateAsync({
                                      title: values.title,
                                      repoId: repoId as string
                                    })
                                  }}
                                >
                                  <TextField name="title" label="Title" />
                                </Form>
                              </DialogContent>
                            </Dialog>
                          </div>
                          {pages?.map((page) => (
                            <div
                              key={page.id}
                              className={cn(
                                "text-xs hover:bg-gray-100 py-1 px-2 rounded cursor-pointer flex justify-between items-center",
                                selectedPage?.id === page.id && "bg-gray-200"
                              )}
                              onClick={() => {
                                setSelectedPage?.(page)
                              }}
                            >
                              <div className="flex-1 min-w-0">
                                <span className="block text-ellipsis overflow-hidden whitespace-nowrap">{page.title}</span>
                              </div>
                              <div className="flex flex-row gap-2 items-center">
                                <div>
                                  {!page.lastPublishedAt ? (
                                    <span className="text-[10px] text-gray-400 bg-gray-100 rounded-xs px-1">Draft</span>
                                  ) : new Date(page.updatedAt) > new Date(page.lastPublishedAt) ? (
                                    <span className="text-[10px] text-blue-500 bg-blue-100 rounded-xs px-1">Modified</span>
                                  ) : (
                                    <span className="text-[10px] text-green-500 bg-green-100 rounded-xs px-1">Published</span>
                                  )}
                                </div>
                                <DropdownMenu>
                                  <DropdownMenuTrigger asChild className="focus:outline-none">
                                    <Ellipsis className="w-4 h-4" />
                                  </DropdownMenuTrigger>
                                  <DropdownMenuContent onClick={(e) => e.stopPropagation()}>
                                    <DropdownMenuItem asChild disabled={!page.htmlUrl} onClick={(e) => e.stopPropagation()}>
                                      <button className="w-full text-xs" onClick={(e) => {
                                        e.stopPropagation();
                                        navigator.clipboard.writeText(page.htmlUrl || "")
                                      }}>
                                        <span className="flex flex-row gap-2 items-center"><Clipboard className="w-3 h-3" /> Copy URL</span>
                                      </button>
                                    </DropdownMenuItem>
                                    <DropdownMenuItem asChild disabled={!page.rawUrl} onClick={(e) => e.stopPropagation()}>
                                      <button className="w-full text-xs" onClick={(e) => {
                                        e.stopPropagation();
                                        fetch(page.rawUrl || "")
                                          .then(response => response.text())
                                          .then(content => {
                                            const blob = new Blob([content], { type: 'text/markdown' });
                                            const url = URL.createObjectURL(blob);
                                            const a = document.createElement('a');
                                            a.href = url;
                                            a.download = `${page.title}.md`;
                                            document.body.appendChild(a);
                                            a.click();
                                            document.body.removeChild(a);
                                            URL.revokeObjectURL(url);
                                          })
                                          .catch(error => console.error('Error downloading markdown:', error));
                                      }}>
                                        <span className="flex flex-row gap-2 items-center"><FileDown className="w-3 h-3" /> Download Markdown</span>
                                      </button>
                                    </DropdownMenuItem>
                                    {page.title !== 'Index' && <DropdownMenuItem asChild onClick={(e) => e.stopPropagation()}>
                                      <button className="w-full text-xs" onClick={(e) => {
                                        e.stopPropagation();
                                        deletePage.mutateAsync({
                                          repoId: repoId as string,
                                          pageId: page.id as string
                                        });
                                      }}>
                                        <span className="flex flex-row gap-2 items-center"><Trash2 className="w-3 h-3" /> Delete</span>
                                      </button>
                                    </DropdownMenuItem>}
                                  </DropdownMenuContent>
                                </DropdownMenu>
                              </div>
                            </div>
                          ))}

                        </div>
                      </ResizablePanel>
                    </ResizablePanelGroup>
                  </div>
                </div>
              ) : repos ? (
                <div className="flex flex-col h-full">
                  <div className="flex-1 flex flex-col gap-4 overflow-y-auto p-4">
                    {repos.map((repo) => {
                      const storedSelectionsString = localStorage.getItem('repoSelections');
                      const storedSelections: StoredSelections = storedSelectionsString ? JSON.parse(storedSelectionsString) : {};
                      const repoSelection = storedSelections[repo.id] || { fileId: null, annotationId: null };

                      return (
                        <button
                          key={repo.id}
                          className="text-left" // Add appropriate styling
                          onClick={() => {
                            const path = repoSelection.fileId
                              ? `/repos/${repo.id}/files/${repoSelection.fileId}`
                              : `/repos/${repo.id}`;

                            // Update the stored selection for the new repo
                            updateStoredSelection(repoSelection.annotationId, repoSelection.fileId);

                            // Use navigate instead of Link
                            navigate({ to: path });
                          }}
                        >
                          <span>{repo.name}</span>
                        </button>
                      );
                    })}
                  </div>
                </div>
              ) : null}
            </div>


            <div className="sticky flex flex-row gap-2 bottom-0 border-t border-gray-200 bg-white w-full px-4 py-2 overflow-x-hidden">
              <Dialog open={settingsOpen} onOpenChange={handleSettingsOpenChange}>
                <DialogTrigger asChild>
                  <button className="disabled:opacity-50 border rounded p-1 bg-white text-gray-600 hover:text-gray-800 hover:bg-gray-100">
                    <Cog className="w-4 h-4" />
                  </button>
                </DialogTrigger>
                <SettingsDialog isSettingsOpen={isSettingsOpen || false} />
              </Dialog>

            </div>

          </div>
        </ResizablePanel>
        <ResizableHandle withHandle />
        <ResizablePanel defaultSize={defaultLayout[2]} minSize={30}>
          <div className={`p-4 h-screen overflow-y-auto ${isGrayBackground ? 'bg-gray-100' : 'bg-white'}`}>
            {children}
          </div>
        </ResizablePanel>
        <ResizableHandle withHandle />
        <ResizablePanel defaultSize={defaultLayout[1]} minSize={30}>
          <div className={cn("h-screen overflow-y-auto", !files && "bg-gray-100")}>
            {files ?
              <>
                <div className="bg-opacity-70 px-4 py-2 sticky top-0 z-10 bg-white flex gap-4 flex-row">
                  <button
                    className="disabled:opacity-50 border rounded p-2 bg-white text-gray-600 hover:text-gray-800 hover:bg-gray-100"
                    onClick={async () => {
                      await updatePage.mutateAsync(pageQuery.data || "");
                    }}
                  >
                    <span className="flex items-center gap-2 text-xs">
                      {saveStatus === 'idle' && <Save className="w-4 h-4" />}
                      {saveStatus === 'saving' && <Loader className="w-4 h-4 animate-spin" />}
                      {saveStatus === 'success' && <Check className="w-4 h-4" />}
                      {saveStatus === 'idle' && 'Save'}
                      {saveStatus === 'saving' && 'Saving...'}
                      {saveStatus === 'success' && 'Saved'}
                    </span>
                  </button>
                  <button className="disabled:opacity-50 border rounded p-2 bg-white text-gray-600 hover:text-gray-800 hover:bg-gray-100"
                    onClick={async () => {
                      await updatePage.mutateAsync(pageQuery.data || "");
                      await uploadPage.mutateAsync();
                    }}
                  >
                    <span className="flex items-center gap-2 text-xs">
                      {uploadStatus === 'idle' && <CloudUpload className="w-4 h-4" />}
                      {uploadStatus === 'uploading' && <Loader className="w-4 h-4 animate-spin" />}
                      {uploadStatus === 'success' && <Check className="w-4 h-4" />}
                      {uploadStatus === 'idle' && 'Upload'}
                      {uploadStatus === 'uploading' && 'Uploading...'}
                      {uploadStatus === 'success' && 'Uploaded'}
                    </span>
                  </button>


                </div>
                <div className="h-screen">
                  <RichTextField
                    value={pageQuery.data || ""}
                    onValueChange={(e) => {
                      queryClient.setQueryData(['page_content', repoId, selectedPage?.id], e);
                    }}
                  />
                </div>
              </> : null}

          </div>
        </ResizablePanel>
      </ResizablePanelGroup>
    </FileTreeProvider>
  )
}
