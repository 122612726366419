import { Node, mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import { AiContentBlockView } from './components/AiContentBlockView'
import { DOMParser } from 'prosemirror-model'
import { Plugin, PluginKey } from 'prosemirror-state'
import { Node as ProsemirrorNode } from 'prosemirror-model'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    aiContentBlock: {
      setAiContentBlock: (attributes: { content: string, annotationIds: string[], repoId: string, startLine: number, endLine: number }) => ReturnType
      updateAiContentBlockLines: (annotationIds: string[], startLine: number, endLine: number) => ReturnType
      updateAiContentBlockIsClicked: (annotationIds: string[], isClicked: boolean) => ReturnType
      updateAiContentBlockPosition: () => ReturnType
    }
  }
}


export const AiContentBlock = Node.create({
  name: 'aiContentBlock',

  group: 'block',

  content: 'block+',
  defining: true,

  addAttributes() {
    return {
      annotationIds: {
        default: null,
        parseHTML: element => JSON.parse(element.getAttribute('data-annotation-ids') || '[]'),
        renderHTML: attributes => ({
          'data-annotation-ids': JSON.stringify(attributes.annotationIds),
        }),
      },
      repoId: {
        default: null,
        parseHTML: element => element.getAttribute('data-repo-id'),
        renderHTML: attributes => ({
          'data-repo-id': attributes.repoId,
        }),
      },
      pageId: {
        default: null,
        parseHTML: element => element.getAttribute('data-page-id'),
        renderHTML: attributes => ({
          'data-page-id': attributes.pageId,
        }),
      },
      startLine: {
        default: null,
        parseHTML: element => element.getAttribute('data-start-line'),
        renderHTML: attributes => ({
          'data-start-line': attributes.startLine,
        }),
      },
      endLine: {
        default: null,
        parseHTML: element => element.getAttribute('data-end-line'),
        renderHTML: attributes => ({
          'data-end-line': attributes.endLine,
        }),
      },
      isClicked: {
        default: false,
        parseHTML: element => element.getAttribute('data-is-clicked') === 'true',
        renderHTML: attributes => ({
          'data-is-clicked': attributes.isClicked,
        }),
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'div[data-type="ai-content"]',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, { 'data-type': 'ai-content' }), 0]
  },
  addCommands() {
    return {
      updateAiContentBlockIsClicked:
      (annotationIds, isClicked) =>
        ({ state, dispatch }) => {
          if (!state || !dispatch) return false;
          const { tr } = state
          state.doc.descendants((node, pos) => {
            if (node.type.name === 'aiContentBlock' && 
                annotationIds.some(id => node.attrs.annotationIds.includes(id))) {
              tr.setNodeMarkup(pos, null, {
                ...node.attrs,
                isClicked: isClicked,
              })
              return false
            }
            return true
          })
          dispatch(tr)
          return true
        },
    }
  },

  addNodeView() {
    return ReactNodeViewRenderer(AiContentBlockView)
  },
  addOptions() {
    return {
      updateInterval: 1000,
    }
  },
  addProseMirrorPlugins() {
    const plugin = new Plugin({
      key: new PluginKey('aiContentBlockPlugin'),
      appendTransaction: (transactions, oldState, newState) => {
        const tr = newState.tr
        let modified = false
  
        newState.doc.descendants((node: ProsemirrorNode, pos: number) => {
          if (node.type.name === this.name) {
            let lineCount = 0
            newState.doc.nodesBetween(0, pos, (n) => {
              // Count non-empty block nodes, images, and tables as 1 line
              if (n.type.name !== 'aiContentBlock' && 
                ((n.isBlock && n.textContent.trim().length > 0) || 
                 n.type.name === 'image' ||
                 n.type.name === 'table')) {
                lineCount++
                // Stop traversing child nodes for tables
                return n.type.name !== 'table'
              }
              return true
            })
  
            const newStartLine = lineCount === 0 ? 1 : lineCount + 1
  
            // Calculate newEndLine by counting block nodes within the aiContentBlock
            let blockCount = 0
            node.descendants((childNode) => {
              if ((childNode.isBlock && childNode.textContent.trim().length > 0) ||
                  childNode.type.name === 'table') {
                blockCount++
                // Stop traversing child nodes for tables
                return childNode.type.name !== 'table'
              }
            })
  
            const newEndLine = newStartLine + blockCount - 1
  
            if (node.attrs.startLine !== newStartLine || node.attrs.endLine !== newEndLine) {
              tr.setNodeMarkup(pos, null, {
                ...node.attrs,
                startLine: newStartLine,
                endLine: newEndLine,
              })
              modified = true
            }
          }
          return false
        })
  
        return modified ? tr : null
      },
    })
  
    return [plugin]
  }

})


export default AiContentBlock