import { createFileRoute } from '@tanstack/react-router'


export const Repo = () => {
  return (
    <div />
  )
}

export const Route = createFileRoute('/_repos/repos/$repoId/')({
  component: Repo
})