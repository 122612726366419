import { useCallback, useEffect, useRef } from 'react'
import { EditorContent, Editor, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Focus from '@tiptap/extension-focus'
import TextStyle from '@tiptap/extension-text-style'
import { cn } from '@/lib/utils'
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight'
import { createLowlight, common } from 'lowlight'
import css from 'highlight.js/lib/languages/css'
import js from 'highlight.js/lib/languages/javascript'
import ts from 'highlight.js/lib/languages/typescript'
import html from 'highlight.js/lib/languages/xml'
import 'highlight.js/styles/github.css';
import { Command, suggestion } from './plugins/Command'
import { Table, TableCell, TableHeader, TableRow } from './plugins/Table'
import { TableRowMenu, TableColumnMenu } from './plugins/Table/menus'
import { AiWriter } from './plugins/AiWriter'
import { AiContentBlock } from './plugins/AiContentBlock/AiContentBlock'
import ImageUpload from './plugins/ImageUpload/ImageUpload'
import { Image } from './plugins/Image'
import Dropcursor from '@tiptap/extension-dropcursor'
import { TrailingNode } from './plugins/TrailingNode'
import Placeholder from '@tiptap/extension-placeholder'
import { Selection } from './plugins/Selection'
import { Link } from './plugins/Link'
import {TextMenu} from './menus/TextMenu'
import { LinkMenu } from './menus/LinkMenu'
import { ContentItemMenu } from './menus/ContentItemMenu'
import { useEditor as useEditorContext } from './EditorContext';
import { useParams } from '@tanstack/react-router'

const lowlight = createLowlight(common)
lowlight.register('html', html)
lowlight.register('ts', ts)
lowlight.register('js', js)
lowlight.register('css', css)

export const extensions = [
  StarterKit.configure({
    dropcursor: false,
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
    codeBlock: false,
  }),
  TextStyle,
  Table,
  TableRow,
  TableHeader,
  TableCell,
  AiWriter,
  AiContentBlock,
  ImageUpload,
  Image,
  Focus,
  TrailingNode,
  Dropcursor.configure({
    width: 2,
    class: 'ProseMirror-dropcursor border-black',
  }),
  Placeholder.configure({
    includeChildren: true,
    showOnlyCurrent: false,
    placeholder: () => '',
  }),
  Selection,
  Link.configure({
    openOnClick: false,
    HTMLAttributes: {
      class: 'no-underline cursor-pointer text-blue-600 hover:text-blue-800',
    },
  }),
  Command.configure({
    suggestion: suggestion
  }),
  CodeBlockLowlight
    .configure({ lowlight }),
]

export const RichTextField = (
  { value, onValueChange, editable = true }:
    { 
      value: string, 
      onValueChange?: (e: string) => void, 
      editable?: boolean,
    }
) => {

  const editorRef = useRef<Editor | null>(null);
  const menuContainerRef = useRef(null)
  const { setEditor: setEditorContext, editor: editorContext } = useEditorContext();
  const { repoId } = useParams({strict: false})

  const editor = useEditor({
    extensions,
    editorProps: {
      attributes: {
        repoId: repoId || '',
        class: 'prose prose-code:bg-[#f6f8fa] prose-pre:bg-[#f6f8fa] prose-pre:text-inherit focus:outline-none max-w-full',
      }
    },
    onCreate: async (e) => {
      e.editor.chain().scrollIntoView().focus('end').run()
    },
    onUpdate: async (e) => {
      onValueChange?.(e.editor.getHTML());
    }
  })

  useEffect(() => {
    if(!editorContext){
      setEditorContext(editor)
    }
  }, [editorContext])


  useEffect(() => {
    if (value === null && editorRef.current) {
      editorRef.current.commands.setContent('');
    }

    if (editor && value !== editor.getHTML()) {
      editor.commands.setContent(value || '');
    }
  }, [value, editor]);


  if (!editor) {
    return null
  }



  return (
    <>
      <div className="p-4 flex h-full" ref={menuContainerRef}>
        <EditorContent editor={editor} className={cn(editable && "flex w-full antialiased px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50")} />
        <ContentItemMenu editor={editor} />
        <LinkMenu editor={editor} appendTo={menuContainerRef} />
        <TextMenu editor={editor} />
        <TableRowMenu editor={editor} appendTo={menuContainerRef} />
        <TableColumnMenu editor={editor} appendTo={menuContainerRef} />
      </div>
    </>
  )
}
