import { createFileRoute, redirect, useNavigate } from '@tanstack/react-router'
import type { connectRepoSchema } from '@readmewriter/api'
import { queryOptions, useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { SidebarLayout } from '@/components/SidebarLayout';
import { ArrowRight, Github, Loader2 } from 'lucide-react'
import { z } from 'zod'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { useState } from 'react';
import { client } from '@/lib/utils';
import { useAuth, userQueryOptions } from '@/lib/auth';
import { usePostHog } from 'posthog-js/react';
import { selectRepoSchema } from '@readmewriter/db/drizzle/schema';



export const fetchRepos = async () => {
  try {
    const repos = await client.api.repo.$get()
    const data = await repos.json()
    return data
  } catch (error) {
    console.error('Failed to fetch repo data:', error);
    throw error;
  }
}

export const fetchGithubRepos = async () => {
  try {
    const repos = await client.api.repo.getGithubRepos.$get()
    const data = await repos.json()
    return data
  } catch (error) {
    console.error('Failed to fetch github repos:', error);
    throw error;
  }
}



export const repoQueryOptions = () => {
  return queryOptions({
    queryKey: ['all_repos'],
    queryFn: () => fetchRepos()
  })
}

export const githubRepoQueryOptions = () => {
  return queryOptions({
    queryKey: ['github_repos'],
    queryFn: () => fetchGithubRepos(),
    staleTime: 0
  })
}


export const Route = createFileRoute('/')({
  loader: async (opts) => {
    await opts.context.queryClient.ensureQueryData(repoQueryOptions())
    await opts.context.queryClient.ensureQueryData(githubRepoQueryOptions())
  },
  component: Index,
  beforeLoad: async ({ context }) => {
    let user

    if (!context.auth.user) {
      try {
        user = await context.queryClient.fetchQuery(userQueryOptions())
      } catch (error) {
        user = null
      }
    } else {
      user = context.auth.user
    }
    
    if(!user) {
      throw redirect({
        to: '/login'
      })
    }
  }
})



function Index() {
  const repoQuery = useSuspenseQuery(repoQueryOptions())
  const reposWithDates = repoQuery.data.map(file => ({
    ...file,
    createdAt: new Date(file.createdAt),
    updatedAt: new Date(file.updatedAt)
  }))

  const auth = useAuth()
  const [isSettingsOpen, setIsSettingsOpen] = useState(auth.billingPlan?.status === 'canceled' || !auth.billingPlan?.hasBillingPlan)

  return (
    <SidebarLayout repos={reposWithDates} isSettingsOpen={isSettingsOpen}>
      <div className='flex flex-col gap-4 items-center justify-center h-full'>
        <h1 className='text-2xl font-bold'>Select a repo</h1>
        <p className='text-gray-500'>or</p>
        <ConnectDialog />
      </div>
    </SidebarLayout>
  )
}

function ConnectDialog() {
  const githubRepoQuery = useSuspenseQuery(githubRepoQueryOptions())
  const queryClient = useQueryClient()
  const [isOpen, setIsOpen] = useState(false)
  const [loadingRepo, setLoadingRepo] = useState<string | null>(null)
  const navigate = useNavigate()
  const posthog = usePostHog()

  const mutation = useMutation({
    mutationFn: (newRepo: z.infer<typeof connectRepoSchema>) => {
      return client.api.repo.connect.$post({
        json: {
          githubOwner: newRepo.githubOwner,
          githubRepoName: newRepo.githubRepoName,
          githubBranch: newRepo.githubBranch,
          githubUrl: newRepo.githubUrl
        }
      })
    },
    onMutate: (newRepo) => {
      setLoadingRepo(newRepo.githubRepoName) // Set loading state
    },
    onSuccess: async (data) => {
      const repo = await data.json() as z.infer<typeof selectRepoSchema>
      queryClient.invalidateQueries({ queryKey: ['all_repos'] })
      queryClient.invalidateQueries({ queryKey: ['github_repos'] })
      posthog.capture('repo_connected');
      setIsOpen(false) // Close the dialog
      navigate({to: '/repos/$repoId', params: {repoId: repo.id}})
    },
    onSettled: () => {
      setLoadingRepo(null) // Reset loading state
    }
  })

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open)
    if (open) {
      queryClient.invalidateQueries({ queryKey: ['github_repos'] })
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>
        <button onClick={() => setIsOpen(true)} className="disabled:opacity-50 border p-1 rounded inline-flex bg-white text-gray-6000 hover:text-gray-800 hover:bg-gray-100">
          <span className="flex items-center gap-2 text-xs">
            <Github className="w-4 h-4" />
            <span>Connect a new Github Repo</span>
          </span>
        </button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Connect a new Github Repo</DialogTitle>
            <div className='flex flex-col gap-2 h-[300px] overflow-y-auto py-4'>
              {githubRepoQuery.data.map((repo, index) => {
                return (
                  <button key={index} onClick={() => mutation.mutateAsync({
                    githubOwner: repo.owner,
                    githubRepoName: repo.name,
                    githubBranch: repo.branch,
                    githubUrl: repo.url
                  })} className='flex items-center justify-between border text-left rounded p-2 px-4 bg-white text-gray-600 hover:text-gray-800 hover:bg-gray-100'>
                    <span>{repo.name}</span>
                    {loadingRepo === repo.name ? (
                      <Loader2 className='w-4 h-4 animate-spin' /> // Show spinner
                    ) : (
                      <ArrowRight className='w-4 h-4' />
                    )}
                  </button>
                )
              })}
            </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}