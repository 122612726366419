import { SidebarLayout } from '@/components/SidebarLayout'
import { queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import { Outlet, createFileRoute, redirect, useParams } from '@tanstack/react-router'
import { client } from '@/lib/utils';
import { Suspense, useState } from 'react';
import { useAuth } from '@/lib/auth';
import { fileDataQueryOptions } from './_repos/repos/$repoId/files/$fileId';


export const getRepoFiles = async (repoId: string) => {
  try {
    const files = await client.api.files[':repoId'].files.$get({
      param: {
        repoId: repoId
      }
    })

    return files.json()
  } catch (error) {
    console.error('Failed to fetch repo files:', error);
    throw error;
  }
}

export const getRepoPages = async (repoId: string) => {
  try {
    const pages = await client.api.readme[':repoId'].pages.$get({
      param: {
        repoId: repoId
      }
    })
    return pages.json()
  } catch (error) {
    console.error('Failed to fetch repo pages:', error);
    throw error;
  }
}




export const repoQueryOptions = (repoId: string) => {
  return queryOptions({
    queryKey: ['repo_files', repoId],
    queryFn: () => getRepoFiles(repoId)
  })
}


export const repoPagesQueryOptions = (repoId: string) => {
  return queryOptions({
    queryKey: ['repo_pages', repoId],
    queryFn: () => getRepoPages(repoId),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })
}

export const getPageContent = async (repoId: string, pageId: string) => {
  try {
    const pages = await client.api.readme[':repoId'][':pageId'].$get({
      param: {
        repoId: repoId,
        pageId: pageId
      }
    })
    return pages.text()
  } catch (error) {
    console.error('Failed to fetch repo pages:', error);
    throw error;
  }
}





export const pageQueryOptions = (repoId: string, pageId: string) => {
  return queryOptions({
    queryKey: ['page_content', repoId, pageId],
    queryFn: () => getPageContent(repoId, pageId),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })
}




const ReposLayout = () => {
  const { repoId } = useParams({ strict: false }) as { repoId?: string, fileId?: string }

  if(!repoId) {
    return redirect({to: '/'})
  }


  const repoQuery = useSuspenseQuery(repoQueryOptions(repoId))
  const getPages = useSuspenseQuery(repoPagesQueryOptions(repoId))




  const filesWithDates = repoQuery.data.map(file => ({
    ...file,
    createdAt: new Date(file.createdAt),
    updatedAt: new Date(file.updatedAt),
    annotations: file.annotations.map(annotation => ({
      ...annotation,
      createdAt: new Date(annotation.createdAt),
      updatedAt: new Date(annotation.updatedAt)
    }))
  }))

  const pagesWithDates = getPages.data.map(page => ({
    ...page,
    createdAt: new Date(page.createdAt),
    updatedAt: new Date(page.updatedAt),
    lastPublishedAt: page.lastPublishedAt ? new Date(page.lastPublishedAt) : null
  }))


  const auth = useAuth()
  const [isSettingsOpen, setIsSettingsOpen] = useState(auth.billingPlan?.status === 'canceled')
  
  return (
    <SidebarLayout 
      files={filesWithDates} 
      repoId={repoId} 
      isSettingsOpen={isSettingsOpen} 
      pages={pagesWithDates}
    >
      <Suspense>
        <Outlet />
      </Suspense>
    </SidebarLayout>
  )
}

export const Route = createFileRoute('/_repos')({
  
  parseParams: (params) => {
    return {
      // @ts-ignore: Unreachable code error
      repoId: params.repoId as string
      
    }
  },
  loader: async (opts) => {
    const repoFiles = await opts.context.queryClient.ensureQueryData(repoQueryOptions(opts.params.repoId))
    const pages = await opts.context.queryClient.ensureQueryData(repoPagesQueryOptions(opts.params.repoId))
    // Preload file data for each file
    await Promise.all(repoFiles.map(async (file) => {
      const existingQuery = opts.context.queryClient.getQueryData(fileDataQueryOptions(file.id).queryKey)
      if (!existingQuery) {
        await opts.context.queryClient.prefetchQuery(fileDataQueryOptions(file.id))
      }
    }))

    return { repoFiles, pages } 
  },
  component: ReposLayout,
  beforeLoad: async ({ context, params }) => {
    if (!context.auth.user) {
      throw redirect({ to: '/login' })
    }

    // Additional check to ensure the user is part of the workspace
    const repo = await context.queryClient.fetchQuery(repoQueryOptions(params.repoId))
    if(!repo || repo[0].repo.workspace.id !== context.auth.session?.current_workspace_id) {
      throw redirect({ to: '/' })
    }
  }
})

