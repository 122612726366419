import { Image as BaseImage } from '@tiptap/extension-image'
import { Plugin, PluginKey } from 'prosemirror-state'
import { ReplaceStep } from 'prosemirror-transform'

export const Image = BaseImage.extend({
  group: 'block',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('track-image-deletion'),
        appendTransaction: (transactions, oldState, newState) => {
          transactions.forEach(transaction => {
            transaction.steps.forEach(step => {
              if (step instanceof ReplaceStep) {
                const { from, to } = step
                
                // Check if 'from' is within the document's content
                if (from < oldState.doc.content.size && from < newState.doc.content.size) {
                  const oldNode = oldState.doc.nodeAt(from)
                  const newNode = newState.doc.nodeAt(from)
  
                  if (oldNode && oldNode.type.name === 'image' && (!newNode || newNode.type.name !== 'image')) {
                    console.log('Image removed:', oldNode.attrs.src)
                    
                    // You can add your custom tracking logic here
                  }
                }
              }
            })
          })
          return null
        },
      }),
    ]
  },
})

export default Image
