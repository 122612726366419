import React, { createContext, useContext, useState } from 'react';
import { Editor } from '@tiptap/react';

type EditorContextType = {
  editor: Editor | null;
  setEditor: (editor: Editor | null) => void;
};

const EditorContext = createContext<EditorContextType | undefined>(undefined);

export const EditorProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [editor, setEditor] = useState<Editor | null>(null);


  return (
    <EditorContext.Provider value={{ editor, setEditor }}>
      {children}
    </EditorContext.Provider>
  );
};

export const useEditor = () => {
  const context = useContext(EditorContext);
  if (context === undefined) {
    throw new Error('useEditor must be used within an EditorProvider');
  }
  return context;
};