import { NodeViewWrapper, NodeViewWrapperProps } from '@tiptap/react'
import { useCallback, useMemo, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { useParams } from '@tanstack/react-router'
import { Button } from '@/components/ui/Button'
import { Loader } from '@/components/ui/Loader'
import { Panel, PanelHeadline } from '@/components/ui/Panel'
import { Textarea } from '@/components/ui/Textarea'
import { Icon } from '@/components/ui/Icon'
import { useMutation } from '@tanstack/react-query'
import { client } from '@/lib/utils'
import { parse } from 'marked';
import { DOMParser } from 'prosemirror-model'
import { usePage } from '@/components/PageProvider'

export interface DataProps {
  text: string
}

export const AiWriterView = ({ editor, node, getPos, deleteNode }: NodeViewWrapperProps) => {
  const { repoId } = useParams({ strict: false})
  const [data, setData] = useState<DataProps>({
    text: '',
  })
  const [previewText, setPreviewText] = useState<string>('')
  const [isFetching, setIsFetching] = useState(false)
  const [results, setResults] = useState<Array<{ content: string, annotationIds: string[] }>>([])
  const { selectedPage } = usePage();

  const textareaId = useMemo(() => uuid(), [])

  const promptMutation = useMutation({
    mutationFn: async (prompt: string) => {
      try {
        const res = await client.api.ai[':repoId'].ask.$post({
          param: {
            repoId: repoId ?? ''
          },
          json: {
            prompt
          }
        })
        const { results } = await res.json()
        return { results }

      } catch (error) {
        console.error('Failed to generate readme:', error);
        throw error;
      }
    },
    onMutate: () => {
      setIsFetching(true)
    },
    onSuccess: (data) => {
      const htmlResults = data.results.map(result => ({
        content: parse(result.content).toString().replace(/\n/g, ''),
        annotationIds: result.annotationIds
      }))
      setResults(htmlResults)
      setPreviewText(htmlResults.map(r => r.content).join('\n\n'))
      setIsFetching(false)
    },
    onError: () => {
      setIsFetching(false)
    },
  })



  const generateText = useCallback(async () => {
    const { text: dataText } = data

    if (!data.text) {
      console.log("Please enter a description")
      return
    }

    setIsFetching(true)

    try {
      promptMutation.mutate(dataText)
    } catch (errPayload: any) {
      const errorMessage = errPayload?.response?.data?.error
      const message = errorMessage !== 'An error occurred' ? `An error has occured: ${errorMessage}` : errorMessage
      setIsFetching(false)
      console.log(message)
    }
  }, [data])

  const insert = useCallback(async () => {
    const from = getPos()
 
    let chain = editor.chain().focus()

    const reversedResults = [...results].reverse();
    // Insert each result as a separate aiContentBlock
    for (const [index, result] of reversedResults.entries()) {
      const div = document.createElement('div')
      div.innerHTML = result.content


      const fragment = DOMParser.fromSchema(editor.schema).parse(div)
      let content = [...fragment.content.toJSON()]

      chain = chain
        .insertContentAt(from, {
          type: 'aiContentBlock',
          attrs: {
            annotationIds: result.annotationIds,
            pageId: selectedPage?.id,
            startLine: 0,
            endLine: 0,
            repoId: repoId,
            isClicked: false,
          },
          content
        })
        .enter() // Move to the next line after each insertion
      // If it's not the last result, add an extra enter to create space between blocks
      if (index < results.length - 1) {
        chain = chain.enter()
      }
    }

    // Move the cursor to the end and focus
    chain = chain.scrollIntoView()

    chain.run()

    deleteNode()


  }, [editor, previewText, getPos, node.nodeSize, results])
  

  const discard = useCallback(() => {
    deleteNode()
  }, [deleteNode])

  const onTextAreaChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setData(prevData => ({ ...prevData, text: e.target.value }))
  }, [])


  return (
    <NodeViewWrapper data-drag-handle>
      <Panel noShadow className="w-full">
        <div className="flex flex-col p-1">
          {isFetching && <Loader label="I'm thinking about it..." />}
          {previewText && (
            <>
              <PanelHeadline>Preview</PanelHeadline>
              <div
                className="bg-white dark:bg-black border-l-4 border-neutral-100 dark:border-neutral-700 text-black dark:text-white text-base max-h-[14rem] mb-4 ml-2.5 overflow-y-auto px-4 relative"
                dangerouslySetInnerHTML={{ __html: previewText }}
              />
            </>
          )}
          <div className="flex flex-row items-center justify-between gap-1">
            <PanelHeadline asChild>
              <label htmlFor={textareaId}>Prompt</label>
            </PanelHeadline>
          </div>
          <Textarea
            id={textareaId}
            value={data.text}
            onChange={onTextAreaChange}
            placeholder={'Tell me what you want me to write about.'}
            required
            className="mb-2"
          />
          <div className="flex flex-row items-center justify-between gap-1">
            <div className="flex justify-between w-auto gap-1">

            </div>
            <div className="flex justify-between w-auto gap-1">
              {previewText && (
                <Button
                  variant="ghost"
                  className="text-red-500 hover:bg-red-500/10 hover:text-red-500"
                  onClick={discard}
                >
                  <Icon name="Trash" />
                  Discard
                </Button>
              )}
              {previewText && (
                <Button variant="ghost" onClick={insert} disabled={!previewText}>
                  <Icon name="Check" />
                  Insert
                </Button>
              )}
              <Button variant="primary" onClick={generateText} style={{ whiteSpace: 'nowrap' }}>
                {previewText ? <Icon name="Repeat" /> : <Icon name="Sparkles" />}
                {previewText ? 'Regenerate' : 'Generate text'}
              </Button>
            </div>
          </div>
        </div>
      </Panel>
    </NodeViewWrapper>
  )
}
