import React, { useMemo } from "react"
import { schema } from "@readmewriter/db"
import { z } from "zod"
import { Link, useParams } from "@tanstack/react-router"
import { cn } from "@/lib/utils"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"

const AnnotationItem = ({ 
  annotation, 
  isActive, 
  onClick 
}: { 
  annotation: z.infer<typeof schema.selectAnnotationSchema>, 
  isActive: boolean,
  onClick: (annotation: z.infer<typeof schema.selectAnnotationSchema>) => void
}) => (
  <li 
    className={cn("px-2 text-ellipsis overflow-hidden whitespace-nowrap", 
      isActive ? "bg-gray-200 rounded" : "hover:bg-gray-100")}
    onClick={() => onClick(annotation)}
  >
    <Link 
      className="text-ellipsis overflow-hidden whitespace-nowrap cursor-pointer" 
      to={`/repos/${annotation.repoId}/annotations/${annotation.id}`}
    >
      {annotation.description}
    </Link>
  </li>
)

const AnnotationListSidebar = ({ 
  annotations,
  onAnnotationClick
}: { 
  annotations: z.infer<typeof schema.selectAnnotationSchema>[],
  onAnnotationClick: (annotation: z.infer<typeof schema.selectAnnotationSchema>) => void
}) => {
  const { annotationId } = useParams({ strict: false })

  const { activeAnnotations, archivedAnnotations } = useMemo(() => ({
    activeAnnotations: annotations.filter(annotation => annotation.status === 'active'),
    archivedAnnotations: annotations.filter(annotation => annotation.status === 'archived')
  }), [annotations])

  return (
    <div className="flex flex-col gap-8">
      <Tabs defaultValue="all" className="flex flex-col gap-8">
        <TabsList>
          <TabsTrigger value="all">All</TabsTrigger>
          <TabsTrigger value="archived">Archived</TabsTrigger>
        </TabsList>

        <TabsContent value="all">
          <ul className="flex flex-col gap-2">
            {activeAnnotations.map(annotation => (
              <AnnotationItem 
                key={annotation.id} 
                annotation={annotation} 
                isActive={annotation.id === annotationId} 
                onClick={onAnnotationClick}
              />
            ))}
          </ul>
        </TabsContent>

        <TabsContent value="archived">
          <ul className="flex flex-col gap-2">
            {archivedAnnotations.map(annotation => (
              <AnnotationItem 
                key={annotation.id} 
                annotation={annotation} 
                isActive={annotation.id === annotationId}
                onClick={onAnnotationClick}
              />
            ))}
          </ul>
        </TabsContent>
      </Tabs>
    </div>
  )
}

export default AnnotationListSidebar