import { Node, ReactNodeViewRenderer } from '@tiptap/react'
import { ImageUpload as ImageUploadComponent } from './view/ImageUpload'
import { Plugin } from 'prosemirror-state'
import { client } from '@/lib/utils'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    imageUpload: {
      setImageUpload: () => ReturnType
    }
  }
}

export const ImageUpload = Node.create({
  name: 'imageUpload',

  isolating: true,

  defining: true,

  group: 'block',

  draggable: true,

  selectable: true,

  inline: false,

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ]
  },

  renderHTML() {
    return ['div', { 'data-type': this.name }]
  },

  addCommands() {
    return {
      setImageUpload:
        () =>
          ({ commands }) =>
            commands.insertContent(`<div data-type="${this.name}"></div>`),
    }
  },

  addNodeView() {
    return ReactNodeViewRenderer(ImageUploadComponent)
  },

  addProseMirrorPlugins() {
    const uploadPlugin = new Plugin({
      props: {
        handleDOMEvents: {
          drop: (view, event) => {
            const hasFiles = event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files.length > 0

            if (!hasFiles) {
              return false
            }

            const images = Array.from(event.dataTransfer.files).filter(file => /image/i.test(file.type))

            if (images.length === 0) {
              return false
            }

            event.preventDefault()


            const { state } = view
            const { tr } = state
            const coordinates = view.posAtCoords({ left: event.clientX, top: event.clientY })
            if (!coordinates) return false

            const insertPos = coordinates.pos


            images.forEach(async (image) => {
              const reader = new FileReader()
              reader.onload = (e) => {
                const base64 = e.target?.result as string
                const node = tr.doc.type.schema.nodes.image.create({ src: base64 })
                tr.insert(insertPos, node)
                view.dispatch(tr)
              }
              reader.readAsDataURL(image)
            })

            return true
          },
        },
      },
    })

    return [uploadPlugin]
  },
})

export default ImageUpload